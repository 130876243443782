const initialState = {
    data: "home"
}
export default function (state = initialState, action) {

    switch (action.type) {
        case 'GET_CURRENT_PAGE':
            return {
                ...state,
                data: action.page
            }
            break;

        default:
            break;
    }

    return state;
}