import React,{Component} from 'react'
import toggleModal from '../actions/togglePop'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
class FooterMainFixed extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <div id="footer" className="footerMainFixed">
                <div className="container">
                    <label>Note: IDC and your sponsor respects your right to privacy and will not reuse or resell your individual information and responses to any third party<br /> under any circumstances. <a style={{ textDecoration: "underline" }} href="javascript:void(0)" onClick={() => this.props.toggleModal(true)}>Read More</a></label>
                <span>Copyright © 2020 IDC. All rights reserved. <a target="_blank" href="https://www.idc.com/about/privacy">Privacy Policy</a> | <a target="_blank" href="https://www.idc.com/ap/about-idc/terms-of-use">Terms & Conditions</a></span>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{}
}
const matchDispatchToProps=(dispatch)=>{
    return bindActionCreators({ toggleModal: toggleModal},dispatch)
}
export default connect(mapStateToProps,matchDispatchToProps) (FooterMainFixed)