import React, { Component } from 'react'
import { Progress } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAuthentication } from '../actions/loginCheck'
import {Redirect,Link} from 'react-router-dom'
class SectionHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isRedirect: false,
            isRedirectPath:"",
            categoryName: {
                "Vulnerability": {
                    "firstName":"HARDEN",
                    "secondName":"Vulnerability Management",
                    "firstImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-vulnerability.png",
                    "secondImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/Vulnerability.png"
                },
                "Threat": {
                    "firstName":"DEPLETE",
                    "secondName":"Threat Management",
                    "firstImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-threat.png",
                    "secondImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/Threat.png"
                },
                "Identity": {
                    "firstName":"VERIFY",
                    "secondName":"Identity Management",
                    "firstImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-identity.png",
                    "secondImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/x-secure-verify-icon.png"
                },
                "Security Strategy": {
                    "firstName":"STRATEGY",
                    "secondName":"Security Strategy",
                    "firstImage":"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-security.png",
                    "secondImage":"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/white-security.png"
                },
                "Trust": {
                    "firstName":"STRENGTHEN",
                    "secondName":"Trust Management",
                    "firstImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-trust.png",
                    "secondImage": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/Trust.png"
                }
            }
        }
    }
    callSection(value, enableStatus) {
        if (value == "Vulnerability" && enableStatus) {
            if (this.props.fullSurvey.length > 0) {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].vulnerabilityStatus)
                    this.setState({ isRedirect:true,isRedirectPath:"/vulnerability-recommendation"})    
                else
                    this.setState({ isRedirect: true, isRedirectPath: "/vulnerability" })    
            } else {
                this.setState({ isRedirect: true, isRedirectPath: "/vulnerability" })    
            }
        }
        else if (value == "Identity" && enableStatus) {
            if (this.props.fullSurvey.length > 0) {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].identityStatus)
                    this.setState({ isRedirect: true, isRedirectPath: "/identity-recommendation" })
                else
                    this.setState({ isRedirect: true, isRedirectPath: "/identity" })
            } else {
                    this.setState({ isRedirect: true, isRedirectPath: "/identity" })
            }
        }
        else if (value == "Threat" && enableStatus) {
            if (this.props.fullSurvey.length > 0) {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].threatStatus)
                    this.setState({ isRedirect: true, isRedirectPath: "/threat-recommendation" })
                else
                     this.setState({ isRedirect: true, isRedirectPath: "/threat" })
            } else {
                    this.setState({ isRedirect: true, isRedirectPath: "/threat" })
            }
        }
        else if (value == "Trust" && enableStatus) {
            if (this.props.fullSurvey.length > 0) {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1] != undefined) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].trustStatus)
                        this.setState({ isRedirect: true, isRedirectPath: "/trust-recommendation" })
                    else
                        this.setState({ isRedirect: true, isRedirectPath: "/trust" })
                }
            } else {
                this.setState({ isRedirect: true, isRedirectPath: "/trust" })
            }
        }
        else if (value == "Security Strategy" && enableStatus) {
            if (this.props.fullSurvey.length > 0) {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1] != undefined) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].securityStatus)
                        this.setState({ isRedirect: true, isRedirectPath: "/security-recommendation" })
                    else
                        this.setState({ isRedirect: true, isRedirectPath: "/security-strategy" })
                }
            } else {
                this.setState({ isRedirect: true, isRedirectPath: "/security-strategy" })
            }
        }
    }
    getImage(prop, section, image, newImage, sectionNum) {
        if (this.props.fullSurvey[this.props.fullSurvey.length - 1] != undefined) {
            if (section) {
                return (<span className={this.props.id == prop ? "complete-status-img" : "complete-nonactive-img"}>{sectionNum}</span>)
            } else {
                return (<img src={this.props.id == prop ? newImage : image} className="img-fluid header-band-icon" alt="Icon" />)
            }
        } else {
            return (<img src={this.props.id == prop ? newImage : image} className="img-fluid header-band-icon" alt="Icon" />)
        }
    }
    getStyle(fullData) {
        // if(fullData.name=="Identity"||fullData.name=="Trust"){
        //     return { cursor: "" }
        // }else{
            if (fullData.name == "Vulnerability") {
                return { borderLeft: "1px solid #EDEEF0", cursor: (fullData.enableStatus) ? 'pointer' : "" }
            } else {
                return { cursor: (fullData.enableStatus) ? 'pointer' : "" }
            }
        // }
       
    }
    render() {
        if (this.props.timeValue != undefined) {
            if (this.props.calledButton != "back") {
                var time = ((this.props.timeValue - this.props.currentValue + 1) * 30) / 60
            } else {
                var time = ((this.props.timeValue - this.props.lastIndex) * 30) / 60
            }
            var value = Math.floor(((this.props.currentValue - 0.5) / this.props.total) * 100)
        }
        return (
            <div>
                <div className="menu-bar animated slideInRight">
                    <div className="container p-0">
                        <div className="row">
                            {this.props.priorityData.data.map(rec => {
                                return (
                                    <div className="col-sm-2">
                                        <div className={this.props.id == rec.name ? "item-menu active" : "item-menu"} style={this.getStyle(rec)} onClick={() => this.callSection(rec.name, rec.enableStatus)}>
                                            <div className="item-menu-inner">
                                                {this.getImage(rec.name, rec.isComplete, this.state.categoryName[rec.name].firstImage, this.state.categoryName[rec.name].secondImage, rec.priority)}
                                                <div className="item-menu-text">
                                                    <b>{this.state.categoryName[rec.name].firstName}</b>
                                                    <span className="item-menu-name">{this.state.categoryName[rec.name].secondName}</span>
                                                </div>
                                            </div>
                                            {!rec.isComplete&&(
                                                    this.props.timeValue != undefined && (
                                                        <font>
                                                            <b><Progress percent={value} size="small" status="active" strokeColor="#ffff" /></b>
                                                            <span className="expected-duration">Expected Duration: {time} min</span>
                                                        </font>
                                                    )
                                            )}
                                            
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {this.state.isRedirect ? <Redirect to={this.state.isRedirectPath} /> : null}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        auth: state.auth,
        priorityData: state.priorityStatus,
        fullSurvey: state.leadSurvey.data
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getAuthentication: getAuthentication }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(SectionHeader)