import React, { Component } from 'react'
import { Input, Button, notification, Select, TreeSelect, Radio } from 'antd';
import SimpleReactValidator from 'simple-react-validator'
import axios from 'axios'
import { toast } from 'react-toastify'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAuthentication } from '../../actions/loginCheck'
import domainArray from './domainArray'
import {apiURL} from '../../env'
import {Redirect,Link} from 'react-router-dom'
const Option = Select.Option;
const { TextArea } = Input;
class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {

            firstName: "",
            lastName: "",
            email: "",
            jobTitle: "",
            company: "",
            password: "",
            confirmPassword: "",
            termsChecked: false,
            valid: false,
            validMsg: "",
            country: undefined,
            otherCountry: "",
            employeeCount: "",
            industry: undefined,
            otherIndustry: "",
            otherIndustryStatus: false,
            otherCountryStatus: false,
            treeValue: undefined,
            countryArray: [],
            industryData: [],
            domainMsg: "",
            // address:"",
            decisionUnitStatus:"",
            isRedirect:false,
            redirectPath:"",
            regMsg:"",
            tmoneTermsChecked:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentWillMount() {
        axios({
            method: "GET",
            url:apiURL+'industries'
        }).then((res) => {
            this.setState({ industryData:res.data})
        })
        axios({
            method: "GET",
            url: apiURL+'countries/list'
        }).then((res) => {
            // res.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            this.setState({ countryArray: res.data })
        })
    }
    checkDomain() {
        if (this.state.email.trim() != "") {
            let domainPart = this.state.email.toLowerCase().split("@")[1]
            if (domainArray.includes(domainPart)) {
                this.setState({ domainMsg: "Please provide a valid business email address (webmail services are not accepted)." })
            } else {
                this.setState({ domainMsg: "" })
            }
        }
    }
    createAccount(e) {
        if (this.validator.allValid() && this.state.validMsg == "" && this.state.regMsg == "" && this.state.domainMsg == "") {
            this.setState({ valid: true })
            axios({
                method: 'POST',
                url: apiURL+'leads/registerLeads',
                data: {
                    "first_name": this.state.firstName,
                    "last_name": this.state.lastName,
                    "email": this.state.email,
                    "jobTitle": this.state.jobTitle,
                    "company_name": this.state.company,
                    "country": this.state.country,
                    "employeeCount": this.state.employeeCount,
                    "industry": this.state.industry,
                    "otherIndustry": this.state.otherIndustry,
                    "otherCountry": this.state.otherCountry,
                    "password": this.state.password,
                    // "address":this.state.address,
                    "decisionUnitStatus": this.state.decisionUnitStatus,
                    "realm": "user"
                }
            }).then((res) => {
                localStorage.token = res.data.token.id
                this.props.getAuthentication()
                this.setState({ isRedirect:true,redirectPath:"/sponsor"})
            }).catch((err) => {
                if (err.response.data.error.details.messages.email) {
                    toast.error("Email already registered", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate();
        }
    }
    checkPassword() {
        if(this.state.password.length){
            var passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
            if (this.state.password.match(passw)){
               this.setState({regMsg:""})
            } else{
                this.setState({ regMsg: "Password must contain at least 8 characters, including UPPER/lower case and numbers" })
            }
        }else{
            this.setState({ regMsg: "" })
        }
        if (this.state.password == this.state.confirmPassword || this.state.confirmPassword == "")
            this.setState({ validMsg: "" })
        else
            this.setState({ validMsg: "Password doesn't match" })

    }
    changeCountry(value) {
        if (value.label == "Others, Please specify")
            this.setState({ otherCountryStatus: true, country: value.label })
        else {
            this.setState({ country: value.key, otherCountry: "" })
            this.setState({ otherCountryStatus: false })
        }
    }
    getCountries() {
        let list = []
        this.state.countryArray.forEach((data, index) => {
            list.push(<Option key={data.id} value={data.id}>{data.name}</Option>)
        })
        return list
    }
    getIndustires(){    
        let list=[]
        if(this.state.industryData.length>0){
         this.state.industryData[0].data.sort().forEach((rec,index)=>{
             if(rec!="Others, please specify")
            list.push(<Option key={index} value={rec}>{rec}</Option>)
         })
            list.push(<Option key={Math.random()} value={"Others, please specify"}>{"Others, please specify"}</Option>)
        }
        return list
    }
    checkDisabled(){
        if ((Object.values(this.props.urlData).length > 0 && this.props.urlData.sponsor_name == "TM ONE")) {
            return ((this.state.termsChecked == false || this.state.tmoneTermsChecked == false) ? true : false)
        } else {
            return (!this.state.termsChecked && !this.state.valid) ? true : false
        }
    }
    render() {
        return (
            <div className="form-container">
                <h2>Create</h2>
                <div className="row">
                    <div className="col-sm-6 form-group">
                        <Input placeholder="First Name" className="form-control" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                        <span className="validation-error">{this.validator.message('firstName', this.state.firstName, 'required', false, { 'required': "First name is required" })}</span>
                    </div>
                    <div className="col-sm-6 form-group">
                        <Input placeholder="Last Name" className="form-control" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                        <span className="validation-error">{this.validator.message('lastName', this.state.lastName, 'required', false, { 'required': "Last name is required" }, 'text-danger')}</span>
                    </div>
                    <div className="col-sm-6 form-group">
                        <Input type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, domainMsg: "" }, this.checkDomain)} />
                        <span className="validation-error">{this.validator.message('Email', this.state.email, 'required|email', false, { 'required': "Email is required", 'email': 'Invalid email address' })}</span>
                        <span className="validation-error">{this.state.domainMsg}</span>
                    </div>
                    <div className="col-sm-6 form-group">
                        <Input placeholder="Job Title" className="form-control" value={this.state.jobTitle} onChange={(e) => this.setState({ jobTitle: e.target.value })} />
                        <span className="validation-error">{this.validator.message('JobTitle', this.state.jobTitle, 'required', false, { 'required': "Job Title is required" })}</span>
                    </div>
                    <div className="col-sm-12 form-group">
                        <label>Are you a part of the IT security decision making unit?</label>
                        <Radio.Group name="radiogroup" onChange={(e) => this.setState({decisionUnitStatus:e.target.value})}>
                            <Radio value={"yes"}>Yes</Radio>
                            <Radio value={"no"}>No</Radio>
                        </Radio.Group>
                        <span className="validation-error">{this.validator.message('decisionUnitStatus', this.state.decisionUnitStatus, 'required', false, { 'required': "This field is required" })}</span>
                    </div>
                    {/* <div className="col-sm-6 form-group">
                        <TextArea placeholder="Business Address"  autoSize={{ minRows: 1, maxRows: 6 }} onChange={(e) =>{console.log("Address--->",e); this.setState({ address:e.target.value})}} />
                        <span className="validation-error">{this.validator.message('address', this.state.address, 'required', false, { 'required': "Business address is required" })}</span>
                    </div> */}
                    <div className="col-sm-6 form-group">
                        <Input placeholder="Organization" className="form-control" value={this.state.company} onChange={(e) => this.setState({ company: e.target.value })} />
                        <span className="validation-error">{this.validator.message('Organization', this.state.company, 'required', false, { 'required': "Organization is required" })}</span>
                    </div>
                    <div className="col-sm-6 form-group">
                        <Select
                            labelInValue
                            showSearch
                            placeholder="Please select country"
                            // value={this.state.country}
                            style={{ width: '100%' }}
                            className="form-control-select"
                            optionFilterProp="children"
                            onChange={(value) => {
                                if (value.label != "Others, please specify") {
                                    this.setState({ country: value.key, otherCountryStatus: false, otherCountry: "" })
                                } else {
                                    this.setState({ otherCountryStatus: true, country: value.key })
                                }
                            }}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.getCountries()}
                        </Select>
                        <span className="validation-error">{this.validator.message('Country', this.state.country, 'required', false, { 'required': "Country is required" })}</span>
                    </div>
                    {this.state.otherCountryStatus && (
                        <div className="col-sm-12 form-group">
                            <Input type="text" className="form-control" placeholder="Country" value={this.state.otherCountry} onChange={(e) => this.setState({ otherCountry: e.target.value })} />
                            <span className="validation-error">{this.validator.message('Country', this.state.otherCountry, 'required', false, { 'required': "Please specify country" })}</span>
                        </div>
                    )}
                    <div className="col-sm-6 form-group">
                        <Select
                            showSearch
                            // value={this.state.employeeCount}
                            style={{ width: '100%' }}
                            className="form-control-select"
                            placeholder="Number of Employees"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({ employeeCount: value })}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="50-99">50-99</Option>
                            <Option value="100-499">100-499</Option>
                            <Option value="500-999">500-999</Option>
                            <Option value="1,000 to 2,499">1,000 to 2,499</Option>
                            <Option value="2,500 to 4,999">2,500 to 4,999</Option>
                            <Option value="5,000 to 9,999">5,000 to 9,999</Option>
                            <Option value="10,000 or more">10,000 or more</Option>

                        </Select>
                    </div>
                    <div className="col-sm-6 form-group">
                        <Select
                            showSearch
                            // value={this.state.employeeCount}
                            style={{ width: '100%' }}
                            className="form-control-select"
                            placeholder="Please select Industry"
                            optionFilterProp="children"
                            onChange={(value) => {
                                if (value != "Others, please specify") {
                                    this.setState({ industry: value, otherIndustryStatus: false, otherIndustry: "" })
                                } else {
                                    this.setState({ otherIndustryStatus: true, industry: value })
                                }
                            }}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.getIndustires()}
                        </Select>
                        <span className="validation-error">{this.validator.message('Industry', this.state.industry, 'required', false, { 'required': "Industry is required" })}</span>
                    </div>
                    {this.state.otherIndustryStatus && (
                        <div className="col-sm-12 form-group">
                            <Input type="text" className="form-control" placeholder="Industry" value={this.state.otherIndustry} onChange={(e) => this.setState({ otherIndustry: e.target.value })} />
                            <span className="validation-error">{this.validator.message('Industry', this.state.otherIndustry, 'required', false, { 'required': "Please specify industry" })}</span>
                        </div>
                    )}
                    <div className="col-sm-6 form-group">
                        <Input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value, validMsg: "",regMsg:"" }, this.checkPassword)} />
                        <span className="validation-error">{this.validator.message('Password', this.state.password, 'required', false, { 'required': "Password is required" })}</span>
                        <span className="validation-error">{this.state.regMsg}</span>
                    </div>
                    <div className="col-sm-6 form-group">
                        <Input type="password" className="form-control" placeholder="Confirm password" value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value }, this.checkPassword)} />
                        <span className="validation-error">{this.validator.message('Confirm Password', this.state.confirmPassword, 'required', false, { required: 'Please re-enter the new password' })}</span>
                        <span className="validation-error">{this.state.validMsg}</span>
                    </div>
                </div>
                {(Object.values(this.props.urlData).length > 0 && this.props.urlData.sponsor_name == "TM ONE" && this.props.urlData.id =="604749c8b489de109bce3ee2") ? (
                    <React.Fragment>
                        <div className="link-to-signup"><input type="checkbox" checked={this.state.tmoneTermsChecked} onChange={() => this.setState({ tmoneTermsChecked: !this.state.tmoneTermsChecked })} /> I have read and understand the <a href="https://www.tmone.com.my/wp-content/uploads/2021/01/terms-and-conditions.pdf">Terms and Conditions</a> and I agree to the collection of use of my information by TM ONE in accordance with the <a href="https://www.tmone.com.my/wp-content/uploads/2021/01/privacy-notice.pdf">Privacy Notice</a>.</div>
                        <div className="link-to-signup"><input type="checkbox" checked={this.state.termsChecked} onChange={() => this.setState({ termsChecked: !this.state.termsChecked })} /> By clicking Create, you accept the <a href="https://www.idc.com/ap/about-idc/terms-of-use" target="_blank">Terms of Use.</a></div>
                    </React.Fragment>
                ) : (
                    <div className="link-to-signup"><input type="checkbox" checked={this.state.termsChecked} onChange={() => this.setState({ termsChecked: !this.state.termsChecked })} /> By clicking Create, you accept the <a href="https://www.idc.com/ap/about-idc/terms-of-use" target="_blank">Terms of Use.</a></div>
                )}
                <Button disabled={this.checkDisabled()}  className="btn form-btn" onClick={(e) => this.createAccount(e)}>Create </Button>
                <span className="link-to-signup" style={{ marginTop: '20px', display: 'inline-block' }}>Existing User? <a href="javascript:void(0);" onClick={this.props.changeFormStatus}> Click here</a> to Log In</span>
                {this.state.isRedirect?<Redirect to={this.state.redirectPath}/>:null}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { urlData:state.urlData}
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getAuthentication: getAuthentication }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(SignUp);