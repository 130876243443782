export function setEnabledStatus(dataArr) {
    var status = true;
    let arr = dataArr
    arr.forEach((item) => {
        item.enableStatus = status
        if (item.mandatory == true) {
            status = item.isComplete
        }
    })
    return ({ type: "STATUS_ARRAY", priortyArray: arr })
}