import React, { Component, Suspense } from 'react';
/*------------------Packages------------------------------------------*/
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Layout } from 'antd';
import { HashRouter as Router, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/*------------------Packages------------------------------------------*/
/*------------------Css------------------------------------------*/
import './stylesheets/fullpage.css';
import './stylesheets/animate.css';
import './stylesheets/custom.css';
import './stylesheets/style.css';
import 'antd/dist/antd.css';
/*------------------Css------------------------------------------*/
/*------------------Actions--------------------------------------*/
import { getAuthentication } from './actions/loginCheck'
import { getSurveyData } from './actions/getSurveyData'
import { getUrlData } from './actions/getUrlData'
/*------------------Actions--------------------------------------*/
/*------------------Containers-----------------------------------*/
import HomeMain from './Home/container/main'
import Dashboard from './Dashboard/dashboard'
import MainHeader from './Header/mainHeader'
import LoginMain from './Login/container/main'
import Sponsor from './Sponsor/container/main'
import Vulnerability from './Vulnerability/container/main'
import Security from './SecurityStrategy/container/main'
import Identity from './Identity/container/main'
import Threat from './Threat/container/main'
import Trust from './Trust/container/main'
/*------------------Containers-----------------------------------*/
/*------------------Components-----------------------------------*/
import IdentityIntro  from './Identity/components/intro'
import TrustIntro from './Trust/components/intro'
import SecurityIntro from './SecurityStrategy/components/intro'
import VulnerabilityIntro from './Vulnerability/components/intro'
import ThreatIntro from './Threat/components/intro'
import VulnerRecommendation from './Vulnerability/components/recommendation'
import SecurityRecommendation from './SecurityStrategy/components/recommendation'
import IdentityRecommendation from './Identity/components/recommendation'
import ThreatRecommendation from './Threat/components/recommendation'
import TrustRecommendation from './Trust/components/recommendation'

import FooterDetail from './Footer/footerDetail'
import PasswordReset from './passwordReset'
/*------------------Components-----------------------------------*/
const { Content } = Layout;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookie: localStorage.showCookie
    }
  }
  componentWillMount() {
    this.props.getAuthentication()
    this.props.getSurveyData()
    let url = new URL(window.location.href);
    let loc=url.pathname.replace(/\//g, "")
    if (url.pathname!= "/"&&loc!="" ){
      this.props.getUrlData(url.pathname.replace(/\//g, ""))
    }
  }
  render() {
    let keyValue = {}
    if (this.props.priorityData.data.length > 0) {
      this.props.priorityData.data.forEach(rec => {
        keyValue[rec.name] = rec
      })
    }
    let beforeLoginRoutes = (
      <div>
        <Route exact path="/" component={HomeMain} />
        <Route exact path="/login" component={LoginMain} />
        <Route exact path="/password-reset/:id" component={PasswordReset} />
        <Route exact path="/sponsor" render={() =>
          <div>
            {this.props.auth.authStatus == true && this.props.auth.loaded == true && (<Sponsor />)}
            {this.props.auth.authStatus == false && this.props.auth.loaded == true && (<LoginMain />)}
          </div>
        } />
      </div>
    )
    let afterLoginRoutes;
    let sponsorRoute = (
      <div>
        <Route exact path="/" component={HomeMain} />
        <Route exact path="/sponsor" component={Sponsor} />
        <Route exact path="/login" component={LoginMain} />
      </div>
    )
    if (this.props.auth.authData) {
      if (this.props.auth.authData.sponsorId) {
        let expirationDate = new Date(this.props.auth.authData.sponsorLockSixMonthExpiry)
        let localDate = new Date()
        if (localDate > expirationDate) {
          afterLoginRoutes = sponsorRoute
        } else {
          afterLoginRoutes = (
            <div>
              {/* <Route exact path="/password-reset/:id"
                render={() =>
                  <Redirect to="/" />
                }
              />*/}
              <Route exact path="/" component={HomeMain} /> 
              <Route exact path="/login" component={LoginMain} />
              <Route exact path="/dashboard" component={Dashboard}/>
              <Route exact path="/vulnerability-intro" render={()=><div>{(Object.values(keyValue).length > 0 && keyValue["Vulnerability"].enableStatus)&&<VulnerabilityIntro /> }</div>} />
              <Route exact path="/vulnerability" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Vulnerability"].enableStatus)&&<Vulnerability/>}</div>} />
              <Route exact path="/vulnerability-recommendation" render={() => <div>{<VulnerRecommendation/>}</div>} /> 
              <Route exact path="/security-intro" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Security Strategy"].enableStatus) && <SecurityIntro />}</div>} /> 
              <Route exact path="/security-strategy" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Security Strategy"].enableStatus) && <Security /> }</div>} /> 
              <Route exact path="/security-recommendation" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Security Strategy"].isComplete)&&<SecurityRecommendation />}</div>} /> 
              <Route exact path="/identity-intro" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Identity"].enableStatus) && <IdentityIntro /> }</div>} /> 
              <Route exact path="/identity" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Identity"].enableStatus)&&<Identity />}</div>} /> 
              <Route exact path="/identity-recommendation" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Identity"].isComplete)&&<IdentityRecommendation />}</div>} />  
              <Route exact path="/trust-intro" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Trust"].enableStatus) && <TrustIntro />}</div>} />
              <Route exact path="/trust" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Trust"].enableStatus) && <Trust /> }</div>} />
              <Route exact path="/trust-recommendation" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Trust"].isComplete) &&<TrustRecommendation />}</div>} /> 
              <Route exact path="/threat-intro" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Threat"].enableStatus) &&<ThreatIntro /> }</div>} /> 
              <Route exact path="/threat" render={() => <div>{(Object.values(keyValue).length > 0 && keyValue["Threat"].enableStatus)&&<Threat/>}</div>} /> 
              <Route exact path="/threat-recommendation" render={() => <div>{<ThreatRecommendation/>}</div>} /> 
             

            </div>
          )
        }
      } else {
        afterLoginRoutes = sponsorRoute
      }
    }
    var route = ''
    if (this.props.auth.loaded == true && this.props.auth.authStatus == false) {
      route = beforeLoginRoutes
    } else if (this.props.auth.loaded == true && this.props.auth.authStatus == true) {
      route = afterLoginRoutes
    } else {
     
    }
    return (
      <div className="page-wrap">
        <Router >
          <div>
            {(this.props.auth.authStatus && this.props.auth.loaded == true)&& (
              <Layout>
                <MainHeader />
                <Content className="body-wrap">
                  {route}
                  <ToastContainer autoClose={3000} />
                </Content>
              
              </Layout>
            )}
            {(!this.props.auth.authStatus && this.props.auth.loaded == true) && (
              <div>
                <MainHeader />
                {route}
                <ToastContainer autoClose={3000} />
              </div>
            )}
          </div>
        </Router>
        {  /*@cc_on!@*/false || !!document.documentMode && this.state.showCookie == undefined && (
          <div className="cookies-test">
            <p>For your optimum experience, please access this site using Edge, Chrome, Safari or Firefox.</p>
            <a onClick={() => { localStorage.showCookie = false; this.setState({ showCookie: false }) }}>OK</a>
          </div>
        )}
        <FooterDetail/>
      </div>
    );
  }
}
function matchStateToProps(state) {
  return { auth: state.auth, priorityData: state.priorityStatus }
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getUrlData: getUrlData,
    getAuthentication: getAuthentication,
    getSurveyData: getSurveyData }, dispatch)
}
export default connect(matchStateToProps, matchDispatchToProps)(App);