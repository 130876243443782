export const QUEST_REQUEST_START="QUEST_REQUEST_START"
export function questStartRequest(){
    return {
        type: "QUEST_REQUEST_START"
    }
}
export const QUEST_REQUEST_END ="QUEST_REQUEST_END"
export function questEndRequest(){
    return {
        type: "QUEST_REQUEST_END"
    }
}
export const QUEST_REQUEST_ERROR="QUEST_REQUEST_ERROR"
export function questErrorRequest(){
    return {
        type:"QUEST_REQUEST_ERROR"
    }
}