import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { getAuthentication } from '../actions/loginCheck'
// import EditProfile from '../components/editProfile'
// import EditPassword from '../components/editPassword'
import { bindActionCreators } from 'redux';
import { Avatar, Menu, Dropdown} from 'antd'
import { LogoutOutlined} from '@ant-design/icons'
import { Redirect, Link} from 'react-router-dom'
import {apiURL} from '../env'
import { getPage} from '../actions/getPage'
class MainHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalStatus: false,
            passwordModal: false,
            isRedirectStatus:false,
            redirectPath:""
        }
    }
    modalStatusChange() {
        this.setState({ modalStatus: !this.state.modalStatus })
    }
    passwordModalChanged() {
        this.setState({ passwordModal: !this.state.passwordModal })
    }
    logOut() {
        axios({
            method: 'POST',
            url: apiURL+'leads/logout'
        }).then((res) => {
            localStorage.clear()
            this.props.getPage("home")
            this.props.getAuthentication()
            this.setState({ isRedirectStatus:true,redirectPath:"/"})
        })
    }
    headerClicked() {
        this.props.getAuthentication()
    }

    render() {
        // console.log("333",this.props.urlData)
        if (this.props.auth.authData != undefined) {
            var menu = (
                <Menu className="header-logout">
                    <Menu.Item>
                        <Avatar style={{ verticalAlign: 'middle' }} size="small">{this.props.auth.authData.first_name != undefined ? this.props.auth.authData.first_name.charAt(0).toUpperCase() : ""}</Avatar>
                        <span>{this.props.auth.authData.first_name}</span>
                    </Menu.Item>
                    <Menu.Item>
                        <a className="ant-dropdown-link" rel="noopener noreferrer" onClick={() => this.logOut()}> <Avatar style={{ verticalAlign: 'middle' }} size="small"><LogoutOutlined /></Avatar> Log Out</a>
                    </Menu.Item>
                </Menu>
            );
        }
        return (
            <div id="header">
            <header className="navbar fixed-top d-flex align-center">
                <div className="container">
                    <div className="d-flex header-inner align-items-center  justify-content-between w-100">
                        <div className="d-flex header-logo">
                                <Link to="/"><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/idc-home-logo-black.svg" className="img-fluid"/></Link>
                        </div>
                        <ul className="list-block d-none d-md-block">
                            {this.props.auth.authStatus?(
                            ((this.props.auth.authStatus && this.props.auth.authData.sponsorId != undefined) ? (
                                <div>
                                        <li><Link to="/" onClick={() => this.headerClicked()}>Home</Link></li>
                                        <li><Link to="/dashboard" style={{ "textDecoration": "none" }}>Dashboard</Link></li>
                                        <li >
                                            <Dropdown overlay={menu} >
                                                <Link className="ant-dropdown-link" to="/">
                                                    <Avatar style={{ verticalAlign: 'middle' }} size="large">{this.props.auth.authData.first_name.charAt(0).toUpperCase()}</Avatar>
                                                </Link>
                                            </Dropdown>
                                        </li>
                                </div>
                            ) : (
                                    <div>
                                                <li onClick={() => this.props.getPage("home")} className={this.props.page.data == "home" ? "active" : ""}><Link to="/" >Home</Link></li>
                                                <li onClick={() => this.props.getPage("works")} className={this.props.page.data== "works" ? "active" : ""}><Link to="/login" >How it Works</Link></li>
                                                <li onClick={() => this.props.getPage("login")} className={this.props.page.data== "login" ? "active" : ""}>  <Dropdown overlay={menu} >
                                                    <a>
                                                        <Avatar style={{ verticalAlign: 'middle' }} size="large">{this.props.auth.authData.first_name.charAt(0).toUpperCase()}</Avatar>
                                                    </a>
                                                </Dropdown></li>
                                    </div>
                                )
                            )):(
                                    <div>
                                            <li onClick={() => this.props.getPage("home")} className={this.props.page.data == "home" ? "active" : ""}><Link to="/" >Home</Link></li>
                                            <li onClick={() => this.props.getPage("works")} className={this.props.page.data == "works" ? "active" : ""}><Link to="/login" >How it Works</Link></li>
                                            <li onClick={() => this.props.getPage("login")} className={this.props.page.data== "login" ? "active" : ""}><Link to="/login" >Login</Link></li>
                                    </div>
                            )}
                          
                        </ul>

                         <div className="d-block d-md-none mob-menu">
                            <button className="js-menu menu" type="button"><span className="bar"></span></button>
                            <nav className="navslide">
                               
                                        {this.props.auth.authStatus ? (
                                            ((this.props.auth.authStatus && this.props.auth.authData.sponsorId != undefined) ? (
                                                <ul className="d-block">
                                                    <li><Link to="/" onClick={() => this.headerClicked()}>Home</Link></li>
                                                    <li><Link to="/dashboard" style={{ "textDecoration": "none" }}>Dashboard</Link></li>
                                                    <li className="mobile-logout"><Link  onClick={() => this.logOut()}>Logout</Link></li>
                                                </ul>
                                            ):(
                                                <ul className="d-block">
                                                    <li onClick={() => this.props.getPage("home")} className={this.props.page.data == "home" ? "active" : ""}><Link to="/" >Home</Link></li>
                                                    <li onClick={() => this.props.getPage("works")} className={this.props.page.data == "works" ? "active" : ""}><Link to="/login" >How it Works</Link></li>
                                                    <li className="mobile-logout"><Link onClick={() => this.logOut()}>Logout</Link></li>
                                                </ul>
                                            ))
                                        ):(
                                            <ul className="d-block">
                                                <li onClick={() => this.props.getPage("home")} className={this.props.page.data == "home" ? "active" : ""}><Link to="/" >Home</Link></li>
                                                <li onClick={() => this.props.getPage("works")} className={this.props.page.data == "works" ? "active" : ""}><Link to="/login" >How it Works</Link></li>
                                                <li onClick={() => this.props.getPage("login")} className={this.props.page.data == "login" ? "active" : ""}><Link to="/login" >Login</Link></li>
                                            </ul>
                                        )} 
                                    
                               
                            </nav>
                        </div>


                        <div className="company-logo">
                                {Object.values(this.props.urlData).length > 0 ?(
                                    <Link to="/" onClick={() => this.props.getPage("home")}><img src={this.props.urlData.logo_url}  className="img-fluid" /></Link>
                            // ):(
                            //         // <Link to="/" onClick={() => this.props.getPage("home")}><img src= "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/centurylink-logo-grey.png" className = "img-fluid"/></Link>
                            // )
                            ):(
                                (this.props.auth.authStatus && this.props.auth.authData.sponsorId != undefined) &&(
                                     <Link to="/" onClick={() => this.props.getPage("home")}><img src={this.props.auth && this.props.auth.authData.sponsor_url} className="img-fluid" /></Link>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </header>
                {this.state.isRedirectStatus?<Redirect to={this.state.redirectPath}/>:null}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { page: state.currentPage, auth: state.auth, urlData: state.urlData }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getPage: getPage, getAuthentication: getAuthentication }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(MainHeader)