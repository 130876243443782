const defaultState={
    loading:false
}
export default function(state=defaultState,action){
    switch(action.type){
        case "QUEST_REQUEST_START":
            return Object.assign({},state,{loading:true})
        case "QUEST_REQUEST_END":
            return Object.assign({},state,{loading:false})
        default:
            return state
    }
}