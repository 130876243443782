const initialState={
    authStatus:false,
    loaded:false,
    authType:"AUTH_FAILED",
    authData:{},
    surveyData:[]
}
export default function(state=initialState,action){
    switch(action.type){
        case "GET_AUTH":
        return{...state,
            authStatus:action.payload.status,
            loaded:action.payload.loaded,
            authType:action.payload.authType,
            authData:action.payload.authData,
            surveyData:action.payload.surveyData,
            page:action.payload.page
        }
        break;
    }
    return state
}