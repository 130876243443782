import { combineReducers } from 'redux'
import app from './app';
import auth from './auth'
import getSurvey from './getSurvey'
import getCategories from './getCategories'
import getQuestionById from './getQuestionById'
import getSponsors from './getSponsors'
import authStatus from './authStatus'
import questionLoader from './questionLoader'
import toggleModal from './togglePop'
import leadSurvey from './getSurveyData'
import urlData from './getUrlData'
import priorityStatus from './setPriority'
import currentPage from './getPage'
const allReducers = combineReducers({
	app,auth,getSurvey,getCategories,getQuestionById,getSponsors,
	authStatus, questionLoader, toggleModal, leadSurvey, urlData, priorityStatus, currentPage
});

export default allReducers