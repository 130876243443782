import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SectionHeader from '../../Header/sectionHeader'
import { Link } from 'react-router-dom'
class SecurityIntro extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div class="intro-wrap">
                        <SectionHeader id="Security Strategy" />
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div class="intro-img">
                                    <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/vulnerability-into.png" className="img-fluid" alt="vulnerability image" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="intro-cnt">
                                    <h1>Strategy Management</h1>
                                    <p><span className="d-block">"Harden the targets of activity"</span>
                                    Vulnerability management incorporates the processes and technologies to assess the attack surface and manage the security posture to protect an organization's assets and applications.</p>
                                    <Link to="/security-strategy" className="intro-start-btn">Start here</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {}
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(SecurityIntro)