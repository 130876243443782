import axios from 'axios'
import {startRequest,endRequest,endRequestError} from './app';
import athStatus from './authStatus'
import { apiURL } from '../env'
export function loginCheck(email, password, fromLogin) {
    return dispatch => {
        axios({
            method: 'POST',
            url: apiURL+'leads/login',
            data: { email: email, password: password }
        }).then((response) => {
            localStorage.token = response.data.token.id
            axios.defaults.headers.common['Authorization'] = response.data.token.id
            dispatch(getAuthentication());
            if(fromLogin){
                if (response.data.sponsorId!==undefined) {
                    let expirationDate = new Date(response.data.sponsorLockSixMonthExpiry)
                    let localDate = new Date()
                    if (localDate > expirationDate) {
                        window.location.href = "/#/sponsor"
                    } else {
                        window.location.href = "/#/dashboard"
                    }
                } else {
                        window.location.href ="/#/sponsor"
                }
            }
        }).catch((err) => {
            dispatch({
                type: "GET_AUTH",
                payload: { status: false, loaded: true, authType: "LOGIN_FAILED" }
            })
        })
    }
}
export function getAuthentication(page="common") {
    var token = localStorage.token
    return dispatch => {
        dispatch(startRequest())
        dispatch(athStatus(false))
        axios.get(apiURL+'leads/isLogedIn?access_token=' + token).then(function (res) {
            if (res.data.data.loggedStatus) {
                axios.defaults.headers.common['Authorization'] = token;
                dispatch({
                    type: "GET_AUTH",
                    payload: { status: true, loaded: true, authType: "AUTH_SUCCESS", authData: res.data.data.leadData,surveyData:res.data.data.surveyData,page:page }
                })
            }
            else {
                dispatch({
                    type: "GET_AUTH",
                    payload: { status: false, loaded: true, authType: "AUTH_FAILED",page:page }
                })
            }
            dispatch(endRequest())
            dispatch(athStatus(true))
        }).catch((err) => {
            console.log("error", err)
        })
    }
}