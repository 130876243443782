import React, { Component } from 'react'
import { Input, Button, notification, Modal } from 'antd'
import SimpleReactVaidator from 'simple-react-validator'
import axios from 'axios'
import { toast } from 'react-toastify'
import {Redirect} from 'react-router-dom'
import {apiURL} from './env'
class PasswordReset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newPassword: "",
            confirmPassword: "",
            isRedirect:false,
            redirectPath:""
        }
        this.validator = new SimpleReactVaidator()
    }
    changePassword(e) {
        if (this.validator.allValid()&&this.state.regMsg=="") {
            axios({
                method: 'POST',
                url: apiURL+'leads/reset-password?access_token=' + this.props.match.params.id,
                data: { newPassword: this.state.newPassword }
            }).then((res) => {
                notification['success']({
                    message: 'Password Reset',
                    description: 'Success',
                });
               this.setState({isRedirect:true,redirectPath:"/"})
            }).catch((err) => {
                console.log(err.response.data.error)
                if (err.response.data.error) {
                    toast.error("Your token has been expired", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            })
        }
        else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    cancelReset() {
        this.setState({ isRedirect: true, redirectPath: "/" })
    }
    checkPassword(){
        if (this.state.newPassword.trim() != "") {
            var passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
            if (this.state.newPassword.match(passw)) {
                this.setState({ regMsg: "" })
            } else {
                this.setState({ regMsg: "Password must contain at least 8 characters, including UPPER/lower case and numbers" })
            }
        } else {
            this.setState({ regMsg: "" })
        }
    }
    render() {
        return (
            <React.Fragment>
            <Modal
                title="Password Reset"
                visible={true}
                footer={null}
                onCancel={() => this.cancelReset()}
                className="reset-password-modal"
            >
                <div className="reset-password-form">
                <div className="form-group">
                    <Input type="password" className="form-control" placeholder="Enter New Password" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value },this.checkPassword)} />
                    <span className="validation-error">{this.validator.message('New Password', this.state.newPassword, 'required', false, { "required": "New Password field is required" })}</span>
                    <span className="validation-error">{this.state.regMsg}</span>
                </div>
                <div className="form-group">
                    <Input type="password" className="form-control" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                    <span className="validation-error">{this.validator.message('Confirm Password', this.state.confirmPassword, 'required|in:' + this.state.newPassword, false, { required: "Please re-enter the new password", in: "Passsword doesn't match" })}</span>
                </div>
                <div className="reset-password-buttons d-flex justify-content-between">
                    <Button className="cancel-btn" onClick={() => this.cancelReset()}>CANCEL</Button>
                    <Button className="submit-btn" onClick={(e) => this.changePassword(e)}>SUBMIT</Button>
                </div>
                </div>
            </Modal>
            {this.state.isRedirect?<Redirect to={this.state.redirectPath}/>:null}
            </React.Fragment>
        )
    }
}
export default PasswordReset