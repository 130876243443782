const initialState = {}
export default function (state = initialState, action) {
    switch (action.type) {
        case 'GET_URL_DATA':
            return {
                ...state,...action.payload
            }
            break;
    }
    return state
}