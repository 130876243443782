import React,{Component} from 'react'
import {apiURL} from '../../env'
import { toast } from 'react-toastify'
import { MailOutlined } from '@ant-design/icons'
import { Button, Input, Form} from 'antd';
import axios from 'axios'
const FormItem = Form.Item;
class ForgotPassword extends Component{
    constructor(props){
        super(props)
        this.state={
            forgotText: "SUBMIT",
            forgotState: false,  
        }
    }
    passwordForgot(values) {
       if(values){
           this.setState({ forgotState: true })
           axios({
               method: "GET",
               url: apiURL+'leads/forgotPassword',
               params: {
                   email: values.forgotemail,
                   origin: window.location.origin
               }
           }).then((res) => {
               this.setState({ forgotText: res.data.status.message })
               setTimeout(() => {
                   this.setState({ forgotText: "Submit", forgotState: false })
               }, 3000)
           }).catch((err) => {
               this.setState({ forgotState: false })
               toast.error("Email not found", {
                   position: toast.POSITION.TOP_RIGHT
               });
           })
       }
    }
    render(){
        return(
            <div className="form-container" style={{height:'auto'}}>
                <Form onFinish={this.passwordForgot.bind(this)} >
                    <FormItem name="forgotemail"
                        rules={[{
                            type: 'email',
                            message: 'The input is not valid E-mail',
                            transform(value) {
                                if (value == undefined) {
                                    return value;
                                } else {
                                    return value.trim()
                                }
                            }
                        }, {
                            required: true,
                            message: 'Please input your E-mail',
                        }
                        ]}
                    >
                    <div className="form-group mb-0">
                        <Input id="email" className="form-control" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    </div>
                    </FormItem>
                    <FormItem>
                        <Button className="btn form-btn" loading={this.state.forgotState} type="primary" htmlType="submit">
                            {this.state.forgotText}
                        </Button>
                    </FormItem>
                </Form>
                <span className="link-to-signup"><a href="javascript:void(0);" onClick={() => this.props.changeForgotStatus() }>Back To Login</a></span>
            </div>
        )
    }
}
export default ForgotPassword

