import RECEIVE_START from '../actions/app'

const defaultState= {
	loading:false,
}
export default function (state=defaultState,action) {
	switch(action.type){
		case "REQUEST_START":
      return Object.assign({}, state, {
        loading:true
    });
    case "REQUEST_END":
      return Object.assign({}, state, {
        loading:false
      });
    case "REQUEST_ERROR":
      return Object.assign({}, state, {
        loading:false,error:true
      });
      default:
        return state
	}
}



