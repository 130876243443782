import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { getSponsors } from '../../actions/getSponsors'
import axios from 'axios'
import { Select, Button, AutoComplete, Avatar, Tooltip } from 'antd';
import { getAuthentication } from '../../actions/loginCheck'
import {apiURL} from '../../env'
import { Redirect, Link } from 'react-router-dom'
import { getPage } from '../../actions/getPage'
import { LoadingOutlined } from '@ant-design/icons';
const Option = Select.Option;
class Sponsor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sponsor: "",
            selectedSponsorId: "",
            selectSponsor:"",
            selectedType: Object.values(this.props.urlData).length > 0 ?"text":"",
            errorMsg: "",
            visibleStatus: true,
            sponsorNameArray: [],
            isRedirect:false,
            redirectPath:"",
            loading:false,
        }
    }
    componentDidMount() {
        // this.nameInput.focus();
    }
    componentWillMount() {
        this.props.getPage("home")
        this.props.getSponsors()
        if (Object.values(this.props.urlData).length > 0){
            axios({
                method: "GET",
                url: apiURL + "sponsor/getCouponCodes?sponsorId=" + this.props.urlData._id
            }).then(res=>{
                this.setState({ sponsor: res.data?res.data[0].coupon_code:""})
            })
        }
        // if (this.props.auth) {
        //     if (this.props.auth.hasOwnProperty('sponsorLockSixMonthExpiry')) {
        //         let localDate = new Date()
        //         var date = new Date(this.props.auth.sponsorLockSixMonthExpiry);
        //         if (localDate > date) {
        //             this.setState({ visibleStatus: true })
        //         } else {
        //             this.setState({ visibleStatus: false })
        //         }
        //     } else {
        //         this.setState({ visibleStatus: true })
        //     }
        // }
    }
    getSponsorName(value, key) {
        if (value != "") {
            axios({
                method: "GET",
                url: apiURL+"sponsor/listSponsorNames?search=" + value
            }).then((res) => {
                this.setState({ sponsorNameArray: res.data.data })
            })
        } else  
            this.setState({ sponsorNameArray: [] })
    }
    loginClicked() {
        if ((this.state.selectedType != "") && (this.state.sponsor || this.state.selectedSponsorId)){
            if (this.state.selectedType == 'text') {
                this.setState({loading:true})
                axios({
                    method: "PATCH",
                    url: apiURL + 'leads/linkSponsorToLead/' + this.props.auth.id + '?access_token=' + localStorage.token,
                    data: {
                        "fromCoupon": true,
                        "sponsorId": (Object.values(this.props.urlData).length > 0) ? this.props.urlData._id : "",
                        "couponCode": this.state.sponsor,
                        "fromUrl": (Object.values(this.props.urlData).length > 0) ? true : false
                    }
                }).then((res) => {
                    this.setState({ loading: false })
                    this.props.getAuthentication()
                    this.setState({ isRedirect: true, redirectPath: "/dashboard" })
                }).catch((err) => {
                    this.setState({ loading: false })
                    if (err.response.data.error.message) {
                        this.setState({ errorMsg: err.response.data.error.message })
                    }
                })
            }
            else if (this.state.selectedType == 'dropdown') {
                this.setState({ loading:true })
                axios({
                    method: "PATCH",
                    url: apiURL + 'leads/linkSponsorToLead/' + this.props.auth.id + '?access_token=' + localStorage.token,
                    data: {
                        "fromCoupon": false,
                        "sponsorId": this.state.selectedSponsorId,
                        "couponCode": ""
                    }
                }).then((res) => {
                    this.setState({ loading: false })
                    this.props.getAuthentication()
                    this.setState({ isRedirect: true, redirectPath: "/dashboard" })
                }).catch((err) => {
                    this.setState({ loading: false })
                    if (err.response.data.error.message) {
                        this.setState({ errorMsg: err.response.data.error.message })
                    }
                })
            }
        }
       
    }
    render() {
        const children = this.state.sponsorNameArray.map((data, value) => <Option key={data._id} value={data._id} label={data.sponsor_name} >{data.sponsor_name}</Option>);
        return (
            <section className="home-body-wrap login-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 ">
                            <div className="intro-content">
                                <div className="each-step">
                                    <div className="step-description">
                                        <div className="step-description-wrap" style={{ backgroundColor: "#e4f1f8", padding: "10px" }}>
                                            <span><b>Important Note:</b> You are invited to participate in this assessment based on the invitation from your sponsor who is the controller of your personal data. IDC acts as the personal data processor for your sponsor. The processing of your personal data is governed by the privacy policy of the respective sponsor. For any requests and/or complaints relating to the processing of your personal data in this survey, please consult the privacy policy of your sponsor.</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="each-step">
                                    <div className="step-description">
                                        <div className="step-description-wrap">
                                            <span>Depending on how you have been introduced to XSecure maturity assessment, you may have been asked to only complete two or more of the disciplines but your score/s will be based on:</span>
                                            <ul className="mt-2" style={{ listStyleType: "disc" }}>
                                                <li>Strategy is a compulsory section and can provide a viewpoint of your overarching security maturity.</li>
                                                <li>For each of the four disciplines (Vulnerability, Threat, Trust and Identity), you will see your maturity level in the result page and a percentage score in the Essential Guidance download report, together with actionable insights and recommendations on your responses.</li>
                                                <li>We will keep track of your overall score and maturity level in the Essential Guidance report based on the completed sections; however, it cannot be a holistic assessment if you have not taken ALL 5 modules.</li>
                                            </ul>
                                            <span>Now let’s begin and all the best on your journey!</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-container form-sponsor-code">
                                {this.props.auth && (
                                    <h3 className="sponsor-title" style={{ color: "#000", fontWeight: 500,textAlign:"left",fontSize:"45px"}}>Hi, {this.props.auth.first_name}{" "}{this.props.auth.last_name}</h3>
                                )}
                                {Object.values(this.props.urlData).length > 0 ? (
                               <React.Fragment>
                                    <label>Please use your <b>SPONSOR CODE</b> to start</label>
                                    <div className="form-group">
                                        <input type="text"  style={{ "position": "relative", "zIndex": 1, borderColor: '#949494', backgroundColor: "#e6f7ff" }} placeholder="Sponsor Code" className="form-control" onChange={(e) => this.setState({ sponsor: e.target.value, selectedSponsorId: "", selectSponsor:"", selectedType: "text",errorMsg:"" })} value={this.state.sponsor} />
                                    </div>
                                    {this.state.selectedType=="text"&&(
                                            <span className="validation-error">{this.state.errorMsg}</span>
                                    )}
                                </React.Fragment>
                                ):(
                                <React.Fragment>
                                    <label>Please use your <b>SPONSOR CODE</b> to start</label>
                                    <div className="form-group">
                                        <input type="text" style={{ "position": "relative", "zIndex": 1, borderColor: '#949494', backgroundColor: "#e6f7ff" }} placeholder="Sponsor Code" className="form-control" onChange={(e) => this.setState({ sponsor: e.target.value, selectedSponsorId: "", selectSponsor: "", selectedType: "text",errorMsg:"" })} value={this.state.sponsor} />
                                    </div>
                                    {this.state.selectedType == "text" && (
                                        <span className="validation-error">{this.state.errorMsg}</span>
                                    )}
                                    <font>OR</font>
                                            <label>You don’t have a sponsor code but would like to participate in IDC XSecure assessment? Name your preferred sponsor.</label>
                                    <div className="form-group">
                                    <Tooltip
                                        placement="topLeft"
                                        title="Use only if you do not have a sponsor code">
                                        <AutoComplete
                                            value={this.state.selectSponsor}
                                            allowClear={true}
                                            style={{ "width": "100%" }}
                                            className="choose-sponsor"
                                            onChange={(e, value) => { this.setState({ selectedSponsorId: e, selectSponsor: (e != undefined ? (value.label && value.label.trim()) : ""), sponsor: "", selectedType: "dropdown", errorMsg:"" }) }}
                                            onSearch={(e, key) => this.getSponsorName(e, key)}
                                            placeholder="Start typing the name of your preferred sponsor"
                                        >
                                            {children}
                                        </AutoComplete>
                                    </Tooltip>
                                {this.state.selectedType == "dropdown" && (
                                        <span className="validation-error" >{this.state.errorMsg}</span>
                                )}
                                </div>
                                </React.Fragment>
                                )}   
                                <div className="form-group">
                                    <Button className="btn form-btn" onClick={() => this.loginClicked()} >BEGIN SURVEY {this.state.loading && <LoadingOutlined style={{ fontSize: 20,color:"#fff" }} spin />}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.isRedirect?<Redirect to={this.state.redirectPath}/>:null}
                </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    return { auth: state.auth.authData, 
            sponsorData: state.getSponsors,
            urlData: state.urlData }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getPage: getPage, getSponsors: getSponsors, getAuthentication: getAuthentication }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(Sponsor)