import React,{Component} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import toggleModal from '../actions/togglePop'
import {Modal} from 'antd'
class FooterDetail extends Component{
    
    render(){

        return(
            <Modal
                title=""
                visible={this.props.status}
                footer={null}
                maskClosable={false}
                onCancel={() =>this.props.toggleModal(false) }
            >
                <p className="disclaimer-note">
                    <p>
                        IDC and your sponsor respect your right to privacy and will not reuse or resell your individual information and responses to any third party under any circumstances.
                    </p>
                    <p>
                        Any and all information gathered will only be shared with your sponsor and for the purpose of this assessment and creating your essential guidance reports.
                    </p>
                    <p>
                        For a detailed explanation of IDC’s personal and corporate data privacy policies, please refer to the links below as these may differ from your sponsoring organization.
                    </p>
                </p>

            </Modal>
        )
    }
}
function  mapStateToProps(state) {
    return {status:state.toggleModal.status}
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ toggleModal: toggleModal }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps) (FooterDetail)