import React, { Component } from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { getAuthentication } from '../../actions/loginCheck'
import { bindActionCreators } from 'redux';
import axios from 'axios'
import SectionHeader from '../../Header/sectionHeader'
import { Redirect } from 'react-router';
import { DownloadOutlined } from '@ant-design/icons';
import {apiURL} from '../../env'
class VulnerRecommendation extends Component {
    constructor(props) {
        super(props)
        this.state = {
                isRedirect:false,
                redirectPath:"",
                "Vulnerability": {
                    "name": "Vulnerability Management", "start": "/vulnerability", "rec": "/vulnerability-recommendation"
                },
                "Security Strategy": {
                    "name": "Security Strategy", "start": "/security-strategy", "rec": "/security-recommendation"
                },
                "Identity": {
                    "name": "Identity Management", "start": "/identity", "rec": "/identity-recommendation"
                },
                "Trust": {
                    "name": "Trust Management", "start": "/trust", "rec": "/trust-recommendation"
                },
                "Threat": {
                    "name": "Threat Management", "start": "/threat", "rec": "/threat-recommendation"
                },
                loading: false,
                loadingStatus: false,
                buttonText: "Download your IDC Essential Guidance ",
        }
        window.onpopstate = function (e) {
            window.history.forward(1);
        }
    }
    componentWillMount() {
        this.props.getAuthentication()
    }
    proceedSection(value, completeStatus) {
        this.props.getAuthentication()
        if (completeStatus) {
            this.setState({ isRedirect: true, redirectPath: value.rec })
        } else {
            this.setState({ isRedirect: true, redirectPath: value.start })
            // window.location.href = value.start
        }
    }
    generatePdf() {
        this.setState({ loadingStatus: true, buttonText: "Downloading..." })
        axios({
            method: "GET",
            url: apiURL +'survey/recommendationReport?surveyId='+this.props.leadSurvey[this.props.leadSurvey.length - 1].id+'&access_token='+localStorage.token
        }).then((res) => {
            this.setState({ pdfRes: res.data }, this.pdfReport)
        })
    }
    pdfReport() {
        this.setState({ loadingStatus: false, buttonText: "Download your IDC Essential Guidance " })
    }
    nextAssessment() {
        this.setState({ isRedirect: true, redirectPath: '/dashboard' })
    }
    getNextSection() {
        let value, name, completeStatus,mandatory;
        if (this.props.priorityData.data.length > 0) {
            this.props.priorityData.data.forEach(rec => {
                if (rec.name == "Vulnerability") {
                    value = rec.priority + 1
                }
                if (value == rec.priority) {
                    name = rec.name
                    completeStatus = rec.isComplete
                    mandatory=rec.mandatory
                }
            })
            let data = this.props.leadSurvey && this.props.leadSurvey[this.props.leadSurvey.length - 1]
            let length = data && data.survey_details.length
            let nameValue = data && data.survey_details[(data.survey_details.length) - 1].questionCategoryName

            if (mandatory == false || name == undefined) {
                if (!(length == 5 && nameValue == "Vulnerability"))
                    return (<div style={{ textAlign: "center", width: "100%" }} > <Button onClick={() => this.nextAssessment()}>NEXT ASSESSMENT</Button> </div>)
            } else {
                return (<div style={{ textAlign: "center", width: "100%" }} > <Button onClick={() => this.proceedSection(this.state[name], completeStatus)}>Proceed to &nbsp;{this.state[name]["name"]}</Button> </div>)
            }
            // if (name != undefined){
            //         return (<div style={{ textAlign: "center", width: "100%" }} > <Button onClick={() => this.proceedSection(this.state[name], completeStatus)}>Proceed to &nbsp;{this.state[name]["name"]}</Button> </div>)
            // }
        }
    }
    getScore(){
        let percentageArr = [
            this.props.leadSurvey.length > 0 && this.props.leadSurvey[this.props.leadSurvey.length - 1].vulnerability,
            this.props.leadSurvey.length > 0 && this.props.leadSurvey[this.props.leadSurvey.length - 1].threat,
            this.props.leadSurvey.length > 0 && this.props.leadSurvey[this.props.leadSurvey.length - 1].securitystrategy,
            this.props.leadSurvey.length > 0 && this.props.leadSurvey[this.props.leadSurvey.length - 1].identity,
            this.props.leadSurvey.length > 0 && this.props.leadSurvey[this.props.leadSurvey.length - 1].trust
        ]
        percentageArr = percentageArr.filter(Number)
        let overAllScore = Math.round((percentageArr.reduce((a, b) => a + b, 0)) / percentageArr.length)
        let scoreStage;
        if (overAllScore < 20) {
            scoreStage = "Stage 1"
        } else if (overAllScore >= 21 && overAllScore <= 50) {
            scoreStage = "Stage 2"
        } else if (overAllScore >= 51 && overAllScore <= 80) {
            scoreStage = "Stage 3"
        } else if (overAllScore > 81) {
            scoreStage = "Stage 4"
        }
        return scoreStage
    }
    getComplementaryReport() {
        let data = this.props.leadSurvey && this.props.leadSurvey[this.props.leadSurvey.length - 1]
        let length = data && data.survey_details.length
        let name = data && data.survey_details[(data.survey_details.length) - 1].questionCategoryName
        if (length == 5 && name == "Vulnerability") {
            return (
                <div style={{ textAlign: "center", width: "100%", }}>
                    <div style={{ display: "inline-block", fontWeight: 400, backgroundColor: "#32995e", borderColor: "#32995e", width: 330, height: 32, margin: "0px 0px 8px", padding: "4px 15px" }}><a style={{ color: "#fff" }} target="_blank" href={"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/complementaryReport.pdf"} download>Get your complimentary report now!</a></div>
                </div>
            )
        }
        // let result = this.props.priorityData.data && this.props.priorityData.data.filter(rec => rec.mandatory == false && rec.isComplete)
        // if (result.length > 0) {
        //     if (result[0].name == "Vulnerability") {
        //         return (
        //             <div style={{ textAlign: "center", width: "100%", }}>
        //                 <div style={{ display: "inline-block", fontWeight: 400, backgroundColor: "#1890ff", borderColor: "#1890ff", width: 330, height: 32, margin: "0px 0px 8px", padding: "4px 15px" }}><a style={{ color: "#fff" }} target="_blank" href={"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/complementaryReport.pdf"} download>Get your complimentary report now! </a></div>
        //             </div>)
        //     }
        // } else {
        //     if (this.props.priorityData.data[this.props.priorityData.data.length - 1].name == "Vulnerability") {
        //         return (<div style={{ textAlign: "center", width: "100%", }}>
        //             <div style={{ display: "inline-block", fontWeight: 400, backgroundColor: "#1890ff", borderColor: "#1890ff", width: 330, height: 32, margin: "0px 0px 8px", padding: "4px 15px" }}><a style={{ color: "#fff" }} target="_blank" href={"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/complementaryReport.pdf"} download>Get your complimentary report now! </a></div>
        //         </div>)
        //     }
        // }
        // if (this.props.priorityData.data && this.props.priorityData.data.length > 0) {
        //     // let result = this.props.priorityData.data && this.props.priorityData.data.filter(rec => rec.mandatory == true)
        //     // if (result.length > 0) {
        //     //     if (result[result.length - 1].name == "Vulnerability") {
        //     //         return (
        //     //             <div style={{ textAlign: "center", width: "100%", }}>
        //     //                 <div style={{ display: "inline-block", fontWeight: 400, backgroundColor: "#32995e", borderColor: "#32995e", width: 330, height: 32, margin: "0px 0px 8px", padding: "4px 15px" }}><a style={{ color: "#fff" }} target="_blank" href={"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/complementaryReport.pdf"} download>Get your complimentary report now! </a></div>
        //     //             </div>)
        //     //     }
        //     // }
        //     let result = this.props.priorityData.data && this.props.priorityData.data.filter(rec => rec.name == "Vulnerability")
        //     if (result[0].priority == 5) {
        //         return (
        //             <div style={{ textAlign: "center", width: "100%", }}>
        //                 <div style={{ display: "inline-block", fontWeight: 400, backgroundColor: "#1890ff", borderColor: "#1890ff", width: 330, height: 32, margin: "0px 0px 8px", padding: "4px 15px" }}><a style={{ color: "#fff" }} target="_blank" href={"https://xsecurestore.s3-ap-southeast-1.amazonaws.com/complementaryReport.pdf"} download>Download your IDC Complementary Report </a></div>
        //             </div>
        //         )
        //     }
        // }
    }
    render() {
        return (
            <div>
                <SectionHeader id="Vulnerability" />
                <div className="container message-container">
                    <div className="message-title">
                        <h2>You have successfully completed vulnerability management</h2>
                        <span className="response-text">This result is based on your responses and may be different from peers within your organization.</span>
                    </div>
                    <hr className="mb-0" style={{ borderColor: '#000', opacity: '0.2' }} />
                    {/* {this.props.auth.surveyData[this.props.auth.surveyData.length-1].dxplatform!=undefined&&( */}
                    {/* <Spin spinning={this.props.app.loading}>*/}
                    {this.props.leadSurvey.length>0&&(
                        <div style={{display:"flex","alignItems":"center",justifyContent:"center"}}>
                        <div className="message-block animated fadeIn">
                            <div className="current-stage" style={{ marginLeft: '20px', textAlign: 'center' }}>
                                <h3 style={{ fontSize: "30px", marginBottom: "5px", fontWeight: "700",color:"#000" }}>
                                    <span style={{ fontSize: "31px", display: "block", marginBottom: "0px", fontWeight: "500" }}>You are in <b style={{ fontSize: "36px", fontWeight: "700", color: "#f16163" }}>{this.getScore()}</b></span></h3>
                                    <span><Button icon={<DownloadOutlined />} className="mt-3 mb-2" type="primary" loading={this.state.loadingStatus} onClick={() => this.generatePdf()}>  <a style={{ color: "#fff" }} href={apiURL +'survey/recommendationReport?surveyId=' + this.props.leadSurvey[this.props.leadSurvey.length - 1].id + '&access_token=' + localStorage.token} >{this.state.buttonText}</a></Button></span>
                                    {/* <span><Button icon={<DownloadOutlined />} className="mt-3 mb-2" type="primary" loading={this.state.loadingStatus} >  <a style={{ color: "#fff" }}>{this.state.buttonText}</a></Button></span> */}
                                <div>
                                    {this.props.auth.authData.sponsor_url && (
                                        <div className="sponsor-front-logo">
                                            <label>Brought to you by</label>
                                            <div className="sponsorlogo-wrap">
                                                <img src={this.props.auth.authData.sponsor_url} className="img-fluid" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        </div>
                    )}
                        
                    {/*</Spin> */}
                    <div className="button-block proceed-btn">
                        {this.getNextSection()}
                    </div>
                    {this.getComplementaryReport()}
                </div>
                {this.state.isRedirect?<Redirect to={this.state.redirectPath}/>:null}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        app: state.app,
        auth: state.auth,
        leadSurvey: state.leadSurvey.data,
        authStatus: state.authStatus,
        priorityData: state.priorityStatus
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getAuthentication: getAuthentication}, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(VulnerRecommendation)