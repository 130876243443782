import React, { Component } from 'react'
import { getAuthentication } from '../actions/loginCheck'
import { getSurveyData } from '../actions/getSurveyData'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, Spin, Icon } from 'antd'
import axios from 'axios'
import FooterMain from '../Footer/main'
import { LoadingOutlined } from '@ant-design/icons'
import { Redirect, Link } from 'react-router-dom'
import { apiURL } from '../env'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vulnerabilityStatus: false,
            securityStatus: false,
            threatStatus: false,
            identityStatus:false,
            trustStatus:false,
            categoryName:
            {
                "Vulnerability": {
                    "fullName": "Vulnerability Management",
                    "icon": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-vulnerability.png",
                    "activeClass": "d-active tactical-active"
                },
                "Identity": {
                    "fullName": "Identity Management",
                    "icon": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-identity.png",
                    "activeClass": "d-active siloed-innovations-active"
                },
                "Threat": {
                    "fullName": "Threat Management",
                    "icon": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-threat.png",
                    "activeClass": "d-active udx-active"
                },
                "Trust": {
                    "fullName": "Trust Management",
                    "icon": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-trust.png",
                    "activeClass": "d-active limited-expertise-active"
                },
                "Security Strategy": {
                    "fullName": "Security Strategy",
                    "icon": "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/grey-security.png",
                    "activeClass": "d-active siloed-dx-active"
                }
            }
        }
    }
    componentWillMount() {
        this.props.getSurveyData()
    }
    generatePdf(path, status) {
        this.setState({ [status]: true })
        axios({
            method: "GET",
            url: path
        }).then((res) => {
            this.setState({ [status]: false })
        }).catch((err) => {
            this.setState({ [status]: false })

        })
    }
    statusText(section, enableStatus) {
        if (section == "Vulnerability") {
            if (this.props.fullSurvey.length && this.props.fullSurvey[this.props.fullSurvey.length - 1].vulnerabilityStatus) {
                return (
                    <Button className="d-btn" onClick={() => this.generatePdf(apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token, "vulnerabilityStatus")}><a href={apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token} download >{this.state.vulnerabilityStatus ? "Downloading" : "Download"}
                        <Spin indicator={antIcon} spinning={this.state.vulnerabilityStatus} />
                    </a></Button>
                    //  <Button className="d-btn" download >Download</Button>
                )
            }
            else if (this.props.fullSurvey.length) {
                if (enableStatus) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].currentQuestionCategoryId == "5bff7a4efde92255455d7e8d") {
                        return <Link to="/vulnerability" className="d-btn">Resume</Link>
                    } else {
                        return <Link to="/vulnerability" className="d-btn">Begin</Link>
                    }
                } else {
                    return <Link className="d-btn d-btn-dissable"><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            }
            else {
                if (enableStatus) {
                    return <Link to="/vulnerability" className="d-btn">Begin</Link>
                } else {
                    return <Link className="d-btn d-btn-dissable"><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            }
        } else if (section == "Identity") {
            if (this.props.fullSurvey.length && this.props.fullSurvey[this.props.fullSurvey.length - 1].identityStatus) {
                return (
                    <Button className="d-btn" onClick={() => this.generatePdf(apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token, "identityStatus")}><a href={apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token} download >{this.state.identityStatus ? "Downloading" : "Download"}
                        <Spin indicator={antIcon} spinning={this.state.identityStatus} />
                    </a></Button>
                )
            } else if (this.props.fullSurvey.length) {
                if (enableStatus) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].currentQuestionCategoryId == "5bff7a4efde92255455d7e8b") {
                        return <Link to="/identity" className="d-btn">Resume</Link>
                    } else {
                        // return <Link className="d-btn d-btn-dissable"><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>
                        return <Link to="/identity" className="d-btn">Begin</Link>
                    }
                } else {
                    // return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>
                    return <Link className="d-btn d-btn-dissable"><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            } else {
                if (enableStatus) {
                    return <Link to="/identity" className="d-btn">Begin</Link>
                } else {
                    // return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>

                    return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            }
        } else if (section == "Threat") {
            if (this.props.fullSurvey.length && this.props.fullSurvey[this.props.fullSurvey.length - 1].threatStatus) {
                return (
                    <Button className="d-btn" onClick={() => this.generatePdf(apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token, "threatStatus")}><a href={apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token} download >{this.state.threatStatus ? "Downloading" : "Download"}
                        <Spin indicator={antIcon} spinning={this.state.threatStatus} />
                    </a></Button>
                )
            } else if (this.props.fullSurvey.length) {
                if (enableStatus) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].currentQuestionCategoryId == "5bff7a4efde92255455d7e8c") {
                        return <Link to="/threat" className="d-btn">Resume</Link>
                    } else {
                        return <Link to="/threat" className="d-btn">Begin</Link>
                    }
                } else {
                    return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            } else {
                if (enableStatus) {
                    return <Link to="/threat" className="d-btn">Begin</Link>
                } else {
                    return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            }
        }
        else if (section == "Trust") {
            if (this.props.fullSurvey.length && this.props.fullSurvey[this.props.fullSurvey.length - 1].trustStatus) {
                return (
                     <Button className="d-btn" onClick={() => this.generatePdf(apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token, "trustStatus")}><a href={apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token} download >{this.state.trustStatus ? "Downloading" : "Download"}
                        <Spin indicator={antIcon} spinning={this.state.trustStatus} />
                    </a></Button>
                )
            } else if (this.props.fullSurvey.length) {
                if (enableStatus) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].currentQuestionCategoryId == "5bff7a4efde92255455d7e8a") {
                        return <Link to="/trust" className="d-btn">Resume</Link>
                    } else {
                        // return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>
                        return <Link to="/trust" className="d-btn">Begin</Link>
                    }
                } else {
                    // return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>
                    return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }

            } else {
                if (enableStatus) {
                    // return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>
                    return <Link to="/trust" className="d-btn">Begin</Link>
                } else {
                    // return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Upcoming</Link>
                    return <Link className="d-btn d-btn-dissable"  ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }
            }
        } else if (section == "Security Strategy") {
            if (this.props.fullSurvey.length && this.props.fullSurvey[this.props.fullSurvey.length - 1].securityStatus) {
                return (
                    <Button className="d-btn" onClick={() => this.generatePdf(apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id + '&access_token=' + localStorage.token, "securityStatus")}><a href={apiURL + "survey/recommendationReport?surveyId=" + this.props.fullSurvey[this.props.fullSurvey.length - 1].id +'&access_token=' + localStorage.token} download >{this.state.securityStatus ? "Downloading" : "Download"}
                        <Spin indicator={antIcon} spinning={this.state.securityStatus} />
                    </a></Button>
                )
            } else if (this.props.fullSurvey.length) {
                if (enableStatus) {
                    if (this.props.fullSurvey[this.props.fullSurvey.length - 1].currentQuestionCategoryId == "5bff7a4efde92255455d7e89") {
                        return <Link to="/security-strategy" className="d-btn">Resume</Link>
                    } else {
                        return <Link to="/security-strategy" className="d-btn">Begin</Link>
                    }
                } else {
                    return <Link className="d-btn d-btn-dissable" ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }

            } else {
                if (enableStatus) {
                    return <Link to="/security-strategy" className="d-btn">Begin</Link>
                } else {
                    return <Link className="d-btn d-btn-dissable" ><img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/lock-icon.svg" />Begin</Link>
                }

            }
        }

    }
    getClass(section, classname) {
        if (this.props.fullSurvey && this.props.fullSurvey.length > 0) {
            if (section == "Vulnerability") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].vulnerabilityStatus) {
                    return classname
                }
            } else if (section == "Identity") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].identityStatus) {
                    return classname
                }
            } else if (section == "Threat") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].threatStatus) {
                    return classname
                }
            } else if (section == "Trust") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].trustStatus) {
                    return classname
                }
            } else if (section == "Security Strategy") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].securityStatus) {
                    return classname
                }
            }
        } else {
            return ""
        }
    }
    completeSectionPercent(section) {
        if (this.props.fullSurvey && this.props.fullSurvey.length > 0) {
            if (section == "Vulnerability") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].vulnerabilityStatus) {
                    return "Completed"
                } else {
                    var data = this.props.fullSurvey[this.props.fullSurvey.length - 1].survey_details.filter(data => data.questionCategoryId == "5bff7a4efde92255455d7e8d")
                    if (data[0])
                        return (Math.floor(((data[0].progressValue[0] + 1 - 0.5) / data[0].progressValue[1]) * 100) + "% Completed")
                }
            }
            if (section == "Threat") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].threatStatus) {
                    return "Completed"
                } else {
                    var data = this.props.fullSurvey[this.props.fullSurvey.length - 1].survey_details.filter(data => data.questionCategoryId == "5bff7a4efde92255455d7e8c")
                    if (data[0])
                        return (Math.floor(((data[0].progressValue[0] + 1 - 0.5) / data[0].progressValue[1]) * 100) + "% Completed")
                }
            }
            if (section == "Identity") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].identityStatus) {
                    return "Completed"
                } else {
                    var data = this.props.fullSurvey[this.props.fullSurvey.length - 1].survey_details.filter(data => data.questionCategoryId == "5bff7a4efde92255455d7e8b")
                    if (data[0])
                        return (Math.floor(((data[0].progressValue[0] + 1 - 0.5) / data[0].progressValue[1]) * 100) + "% Completed")
                }
            }
            if (section == "Trust") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].trustStatus) {
                    return "Completed"
                } else {
                    // return ""
                    var data = this.props.fullSurvey[this.props.fullSurvey.length - 1].survey_details.filter(data => data.questionCategoryId == "5bff7a4efde92255455d7e8a")
                    if (data[0])
                        return (Math.floor(((data[0].progressValue[0] + 1 - 0.5) / data[0].progressValue[1]) * 100) + "% Completed")
                }
            }
            if (section == "Security Strategy") {
                if (this.props.fullSurvey[this.props.fullSurvey.length - 1].securityStatus) {
                    return "Completed"
                } else {
                    // return ""
                    var data = this.props.fullSurvey[this.props.fullSurvey.length - 1].survey_details.filter(data => data.questionCategoryId == "5bff7a4efde92255455d7e89")
                    if (data[0])
                        return (Math.floor(((data[0].progressValue[0] + 1 - 0.5) / data[0].progressValue[1]) * 100) + "% Completed")
                }
            }
        } else {
            return ""
        }
    }

    render() {
        let totalcount = this.props.priorityData.data.filter(rec => { return rec.isComplete == true })
      
        let leftCount = 5 - (totalcount.length)
        return (
            <div>
                {this.props.priorityData.data.length > 0 && (
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="banner-start">
                                    {/* {this.props.priorityData.data.length > 0 && (
                                    <DashboardIntro highLight="pulse" priorityData={this.props.priorityData.data} />
                                )} */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="dashboard-status">
                                    <h1 style={{ textTransform: "capitalize" }}>Welcome, {this.props.authData.authData.first_name + " " + this.props.authData.authData.last_name}</h1>
                                    {totalcount.length == 1 && (
                                        <small>You have successfully completed {totalcount != undefined && this.state.categoryName[totalcount[0].name].fullName}. Please proceed to complete the remaining 4 disciplines.</small>
                                    )}
                                    {((totalcount.length > 1) && (totalcount.length < 5)) && (
                                        <small>You have successfully completed <b>{totalcount.length}</b> disciplines. Please proceed to complete the remaining disciplines.</small>)}
                                    {totalcount.length == 5 && (
                                        <small>You have successfully completed the IDC XSecure Maturity Assessment.</small>
                                    )}
                                    {this.props.priorityData.data.map(rec => {
                                        return (
                                            <div className={"d-item  " + this.getClass(rec.name, this.state.categoryName[rec.name].activeClass)}>
                                                <div className="d-item-left">
                                                    <img src={this.state.categoryName[rec.name].icon} className="img-fluid d-icon" />
                                                    <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/d-done.svg" className="img-fluid d-done" />
                                                    <label>{this.state.categoryName[rec.name].fullName}</label>
                                                </div>
                                                <div className="d-item-right">
                                                    <span className="d-progress">{this.completeSectionPercent(rec.name)}</span>
                                                    {this.statusText(rec.name, rec.enableStatus)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <FooterMain />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        authData: state.auth,
        priorityData: state.priorityStatus,
        fullSurvey: state.leadSurvey.data
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ getSurveyData: getSurveyData, getAuthentication: getAuthentication }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(Dashboard)