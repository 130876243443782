const initialState={
    data:{}
}
export default function (state=initialState,action) {
  
    switch (action.type) {
        case 'GET_QUESTION_CATEGORY':
            return{...state,
                data:action.payload

            }
            break;
    
        default:
            break;
    }

    return state;
}