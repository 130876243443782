import React, { Component } from 'react';
import SimpleReactVaidator from 'simple-react-validator'
import SignUp from '../components/signUp'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Login from '../components/login'
import ForgotPassword from '../components/forgotPassword'
import FooterMain from '../../Footer/main'

class LoginMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            signFormLoad: false,
            formType: false,
            focusChanged: false
        }
        this.validator = new SimpleReactVaidator()
    }

    componentWillReceiveProps(next) {
        this.setState({ focusChanged: false, password: "" })
    }
    changeFormStatus() {
        this.setState({ signFormLoad: !this.state.signFormLoad, formType: false, focusChanged: true })
    }
    forgotPassword() {
        this.setState({ formType: !this.state.formType })
    }
    changeForgotStatus(){
        this.setState({ signFormLoad: false, formType: false, focusChanged: true })
    }
    render() {
        return (
            <React.Fragment>
            <section className="home-body-wrap login-wrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-6 ">
                            <div className="intro-content">
                                <div className="each-step">
                                    <div className="step-description">
                                            <div className="step-description-wrap" style={{ backgroundColor: "#e4f1f8",padding:"10px"}}>
                                            <span><b>Important Note:</b> You are invited to participate in this assessment based on the invitation from your sponsor who is the controller of your personal data. IDC acts as the personal data processor for your sponsor. The processing of your personal data is governed by the privacy policy of the respective sponsor. For any requests and/or complaints relating to the processing of your personal data in this survey, please consult the privacy policy of your sponsor.</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="each-step">
                                    <div className="step-description">
                                        <div className="step-description-wrap">
                                            <span>Depending on how you have been introduced to XSecure maturity assessment, you may have been asked to only complete two or more of the disciplines but your score/s will be based on:</span>
                                            <ul className="mt-2" style={{listStyleType: "disc"}}>
                                                <li>Strategy is a compulsory section and can provide a viewpoint of your overarching security maturity.</li>
                                                <li>For each of the four disciplines (Vulnerability, Threat, Trust and Identity), you will see your maturity level in the result page and a percentage score in the Essential Guidance download report, together with actionable insights and recommendations on your responses.</li>
                                                <li>We will keep track of your overall score and maturity level in the Essential Guidance report based on the completed sections; however, it cannot be a holistic assessment if you have not taken ALL 5 modules.</li>
                                            </ul>
                                            <span>Now let’s begin and all the best on your journey!</span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        
                        <div className="col-sm-6">
                            {!this.state.signFormLoad && !this.state.formType && (
                                <div className="form-container">
                                    <h2>Start Now</h2>
                                    <label>If you have already registered, please sign in.</label>
                                    <Login changeFormStatus={this.changeFormStatus.bind(this)} forgotPassword={this.forgotPassword.bind(this)}/>
                                </div>
                            )}
                            {this.state.formType && (
                                <ForgotPassword changeForgotStatus={this.changeForgotStatus.bind(this)}/>
                            )} 
                            {this.state.signFormLoad && (<SignUp
                                changeFormStatus={this.changeFormStatus.bind(this)}
                            />)}
                        </div>
                    </div>
                </div>
                  
            </section>
            <FooterMain/>
        </React.Fragment>
        )
    }
}

function matchStateToProps(state) {
    return {
        auth: state.auth
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(matchStateToProps, matchDispatchToProps)(LoginMain);