import React,{Component} from 'react'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import SectionHeader from '../../Header/sectionHeader'
import FooterMain from '../../Footer/main'
import {Link} from 'react-router-dom'
class VulnerabilityIntro extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <div>
                <div className="container">
                    <div class="intro-wrap">
                        <SectionHeader id="Vulnerability" />
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div class="intro-img">
                                    <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/vulnerability-into.png" className="img-fluid" alt="vulnerability image"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="intro-cnt">
                                    <h1>Vulnerability Management</h1>
                                    <p><span className="d-block">"Harden the targets of activity"</span> 
                                    Assess the attack surface and manage the security posture to protect an organization's assets and applications.</p>
                                    <Link to="/vulnerability" className="intro-start-btn">Start here</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {}
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({},dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(VulnerabilityIntro)