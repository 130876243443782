import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SectionHeader from '../../Header/sectionHeader'
import { Link } from 'react-router-dom'
class IdentityIntro extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div class="intro-wrap">
                        <SectionHeader id="Identity" />
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div class="intro-img">
                                    <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/vulnerability-into.png" className="img-fluid" alt="vulnerability image" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="intro-cnt">
                                    <h1>Identity Management</h1>
                                    <p><span className="d-block">"Deplete the illegitimate activity"</span>
                                    Threat management incorporates the processes and technologies to monitor activity for attacks and other suspicious actions and investigate and react, respond, and recover from incidents when they occur.</p>
                                    <Link to="/identity" className="intro-start-btn">Start here</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {}
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(IdentityIntro)