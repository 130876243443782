import React, { Component } from 'react'
import ReactFullpage from "@fullpage/react-fullpage";
import FooterMain from '../../Footer/main'
import FooterMainFixed from '../../Footer/footerMainFixed'
import '../../stylesheets/fullpage.css'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { getPage } from '../../actions/getPage'
import { bindActionCreators } from 'redux'
class HomeMain extends Component {
    constructor(props) {
        super(props)
    }
    onLeave(origin, destination, direction) {
        // console.log("Leaving section " + origin.index);
    }
    afterLoad(origin, destination, direction) {
        // console.log("After load: " + destination.index);
    }
    componentWillUnmount() {
        var myobj = document.getElementById("footer");
        myobj.remove();
    }
    render() {
        let keyValue = {}
        if (Object.values(this.props.urlData).length > 0) {
            this.props.urlData.module.forEach(rec => {
                keyValue[rec.name] = rec
            })
        }
        return (
            <ReactFullpage
                licenseKey={null}
                controlArrows={true}
                paddingBottom='110px'
                paddingTop='100px'
                autoScrolling={true}
                navigation={true}
                scrollHorizontally={true}
                scrollOverflow={true}
                fixedElements='#footer'
                sectionsColor={["", "", "]"]}
                navigationTooltips={['XSecure Maturity Assessment', 'Security Strategy', 'Vulnerability', 'Threat','Identity','Trust', ]}
                showActiveTooltip={true}
                onLeave={this.onLeave.bind(this)}
                afterLoad={this.afterLoad.bind(this)}
                render={({ state, fullpageApi }) => {
                    return (
                        <div id="fullpage-wrapper">
                            {/* <div className="section fullBanner intro"
                             style={{backgroundImage: 'url(./assets/images/bg-intro.jpg)'}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>X-Secure</h1>
                                                <p>
                                                 {Object.values(this.props.urlData).length>0?(
                                                        <span>{this.props.urlData.content}</span>
                                                 ) :(
                                                        <span>IDC has developed an interactive assessment tool to help Security and IT executives better understand and cope with the challenges and opportunities that leveraging security disciplines and technologies can bring to their organization.</span>
                                                 )}
                                                
                                                </p>
                                                <a href="#" className="intro-start-btn">Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="section fullBanner x-secure"
                                style={{ backgroundImage: 'url("https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/bg-intro.jpg")' }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>XSecure Maturity Assessment</h1>
                                                {Object.values(this.props.urlData).length > 0 ? (
                                                    // <p>{this.props.urlData.content}</p>
                                                    <p>IDC has developed an interactive assessment tool to help Security and <br />IT executives better understand and cope with the challenges and opportunities that leveraging security disciplines and technologies can bring to their organization.</p>
                                                ) : (
                                                    <p>IDC has developed an interactive assessment tool to help Security and <br/>IT executives better understand and cope with the challenges and opportunities that leveraging security disciplines and technologies can bring to their organization.</p>
                                                )}

                                                <div className="x-secure-manage">
                                                    <div className="x-secure-manage-item">
                                                        <a onClick={() => fullpageApi.moveTo(2)} className="rounded-circle">
                                                            <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/white-security.png" className="img-fluid x-secure-manage-icon" alt="icon" />
                                                            <h6>STRATEGY</h6>
                                                        </a>
                                                        <label>Security<br />Strategy</label>
                                                    </div>
                                                    <div className="x-secure-manage-item">
                                                        <a onClick={() => fullpageApi.moveTo(3)} className="rounded-circle">
                                                            <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/Vulnerability.png" className="img-fluid x-secure-manage-icon" alt="icon" />
                                                            <h6>HARDEN</h6>
                                                        </a>
                                                        <label>Vulnerability<br /> Management</label>
                                                    </div>
                                                    <div className="x-secure-manage-item">
                                                        <a onClick={() => fullpageApi.moveTo(4)} className="rounded-circle">
                                                            <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/Threat.png" className="img-fluid x-secure-manage-icon" alt="icon" />
                                                            <h6>DEPLETE</h6>

                                                        </a>
                                                        <label>Threat<br /> Management</label>
                                                    </div>
                                                    <div className="x-secure-manage-item">
                                                        <a onClick={() => fullpageApi.moveTo(5)} className="rounded-circle">
                                                            <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/x-secure-verify-icon.png" className="img-fluid x-secure-manage-icon" alt="icon" />
                                                            <h6>Verify</h6>

                                                        </a>
                                                        <label>Identity<br /> Management</label>
                                                    </div>
                                                    <div className="x-secure-manage-item">
                                                        <a onClick={() => fullpageApi.moveTo(6)} className="rounded-circle">
                                                            <img src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/Trust.png" className="img-fluid x-secure-manage-icon" alt="icon" />
                                                            <h6>STRENGTHEN</h6>

                                                        </a>
                                                        <label>Trust<br /> Management</label>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section fullBanner security-strategy"
                                style={{ backgroundImage: 'url("https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/bg-security-strategy.jpg")' }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>Security Strategy</h1>
                                                <p>
                                                    {/* IDC has developed an interactive assessment tool to help Security and IT executives better understand and cope with the challenges and opportunities that leveraging security disciplines and technologies can bring to their organization. */}
                                                </p>
                                                <Link to={((this.props.auth.authData == undefined) ? "/login" : ((this.props.auth.authData.sponsorId) ? "/dashboard" : "/sponsor"))} className="intro-start-btn" onClick={() => this.props.getPage("login")}>Get Started</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section fullBanner vulnerability"
                                style={{ backgroundImage: 'url("https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/bg-vulnerability.jpg")' }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>Vulnerability</h1>
                                                <h4>Harden the targets of activity</h4>
                                                <p>
                                                    Assess the attack surface and manage the security posture to protect an organization's assets and applications.
                                                </p>
                                                <Link to={((this.props.auth.authData == undefined) ? "/login" : ((this.props.auth.authData.sponsorId) ? "/dashboard" : "/sponsor"))} className="intro-start-btn" onClick={() => this.props.getPage("login")} >Get Started</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section fullBanner threat"
                                style={{ backgroundImage: 'url("https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/bg-threat.jpg")' }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>Threat</h1>
                                                <h4>Deplete the illegitimate activity</h4>
                                                <p>
                                                    Monitor activities for attacks and other suspicious actions to  investigate, react, respond and recover from incidents when they occur.
                                                </p>
                                                <Link to={((this.props.auth.authData == undefined) ? "/login" : ((this.props.auth.authData.sponsorId) ? "/dashboard" : "/sponsor"))} className="intro-start-btn" onClick={() => this.props.getPage("login")} >Get Started</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section fullBanner dentity"
                                style={{ backgroundImage: 'url("https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/bg-identity.jpg")' }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>Identity</h1>
                                                <h4>Verify the sources of activity</h4>
                                                <p>
                                                    Validate identities and assign/revoke credentials for ongoing authentication and privileges of all types of users.
                                                </p>
                                                <Link to={((this.props.auth.authData == undefined) ? "/login" : ((this.props.auth.authData.sponsorId) ? "/dashboard" : "/sponsor"))} className="intro-start-btn" onClick={() => this.props.getPage("login")}>Get Started</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section fullBanner trust"
                                style={{ backgroundImage: 'url("https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/bg-trust.jpg")' }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9 col-md-7">
                                            <div className="fullpage-cnt">
                                                <h1>Trust</h1>
                                                <h4>Strengthen the legitimate activity</h4>
                                                <p>
                                                    Protect the confidentiality and integrity of an organization's data as it is used throughout the environment.
                                                </p>
                                                <Link to={((this.props.auth.authData == undefined) ? "/login" : ((this.props.auth.authData.sponsorId) ? "/dashboard" : "/sponsor"))} className="intro-start-btn" onClick={() => this.props.getPage("login")}>Get Started</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <FooterMainFixed />
                        </div>
                    );
                }}
            />
        )
    }
}
function mapStateToProps(state) {
    return {
        auth: state.auth,
        urlData: state.urlData
    }
}
const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({ getPage: getPage }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(HomeMain) 