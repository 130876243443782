import React, { Component } from 'react'
/*------------------Packages---------------------------*/
import keydown, { ALL_KEYS } from "react-keydown";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'
import { Button, Tooltip, Spin, Avatar, Tag, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
/*------------------Packages----------------------------*/
/*------------------Actions-----------------------------*/
import { getQuestionById } from '../../actions/getQuestionById'
import { saveSurvey } from '../../actions/saveSurvey'
import { getAuthentication } from '../../actions/loginCheck'
import { getSurveyData } from '../../actions/getSurveyData'
import { questStartRequest } from '../../actions/questionLoader';
import { setEnabledStatus } from '../../actions/setPriority'
/*------------------Actions-----------------------------*/
/*------------------Components-----------------------------*/
import SectionHeader from '../../Header/sectionHeader'
/*------------------Components-----------------------------*/
import { apiURL } from '../../env'

const KEYS = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ENTER", "TAB", "SPACE"]
@keydown(ALL_KEYS)
class Security extends Component {
    constructor(props) {
        super(props)
        this.lastFetchId = 0;
        this.state = {
            orgQuestionStructure: {},
            indexValue: 0,
            percent: 0,
            textAns: "",
            ansArr: [],
            minLeft: 0,
            answerIndex: 0,
            clickedButton: "",
            progressValue: 0,
            selectedImage: "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image.png",
            minValidMsg: "",
            sponsorArray: [],
            selectedSponsorArray: [],
            sponsorNameArray: [],
            sponsorNameStatus: false,
            isRedirect: false,
            redirectPath: ""
        }
    }
    componentDidMount() {
        this.props.questStartRequest()
        setTimeout(() => {
            if (this.props.leadSurvey.length > 0) {
                const result = this.props.leadSurvey[this.props.leadSurvey.length - 1].survey_details.filter(survey => (survey.questionCategoryId) == "5bff7a4efde92255455d7e89");
                if (result.length > 0) {
                    var id = result[0].questions.length - 1
                    this.setState({ indexValue: id })
                } else {
                    this.setState({ indexValue: 0 })
                }
            } else {
                this.setState({ indexValue: 0 })
            }
            if (this.state.indexValue == Object.keys(this.props.orgQuestion.data).length - 1) {
                this.setState({ progressValue: this.state.indexValue })
            }
        }, 1000)
    }
    removeOptions() {
        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length - 2, 1)
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                this.setState({ ansArr: this.state.ansArr })
                if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                    this.checkValidationMsg()
                }
            }
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                this.checkValidationMsg()
            }
        }
    }
    componentWillMount() {
        setTimeout(() => {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            this.props.getQuestionById("5bff7a4efde92255455d7e89", (this.props.leadSurvey.length > 0 ? this.props.leadSurvey[this.props.leadSurvey.length - 1].id : ""))
            if (this.props.leadSurvey.length > 0 && this.props.leadSurvey[this.props.leadSurvey.length - 1].securityStatus) {
                this.setState({ isRedirect: true, redirectPath: "/security-recommendation" })
            } else {
                this.setState({ isRedirect: true, redirectPath: "/security-strategy" })
            }
        }, 3000)
    }
    checkValidationMsg() {
        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length < Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                this.setState({ minValidMsg: "Please choose " + Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice + " options" })
            } else {
                this.setState({ minValidMsg: "" })
            }
        }
    }
    componentWillReceiveProps(next) {
        const { keydown: { event } } = next;
        if (event) {
            var keyTyped = String.fromCharCode(event.which);
            var keyIndex = KEYS.indexOf(keyTyped.toLowerCase());
            this.setState({ key: keyIndex });
            var currentAnswerObject = Object.values(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answers);
            for (var i in currentAnswerObject) {
                if (keyIndex == i) {
                    var indexVal = Number(i) + 1;
                    this.setState({ answerIndex: indexVal })
                    if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Radio") {
                        this.setState({ ansArr: [] })
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray = []
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer = []
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(currentAnswerObject[i].answerId)
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push(currentAnswerObject[i])
                        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                        this.state.ansArr.push(currentAnswerObject[i])
                        this.setState({ ansArr: this.state.ansArr })
                    }
                    else if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Select") {
                        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.includes(currentAnswerObject[i].answerId)) {
                            let indexToDelete = Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.indexOf(currentAnswerObject[i].answerId)
                            Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(indexToDelete, 1)
                            Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(indexToDelete, 1)
                            this.state.ansArr.splice(indexToDelete, 1)
                            this.setState({ ansArr: this.state.ansArr })
                        } else {
                            //remove other oprions when specify option
                            Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.forEach((dbRec, dbInd) => {
                                if (dbRec.specify) {
                                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                                    this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                                    this.state.ansArr.splice(dbInd, 1)
                                    this.setState({ ansArr: this.state.ansArr })
                                }
                            })
                            if (!currentAnswerObject[i].specify) {
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(currentAnswerObject[i].answerId)
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push(currentAnswerObject[i])
                                this.state.ansArr.push(currentAnswerObject[i])
                                this.setState({ ansArr: this.state.ansArr })
                                //remove more optons
                                if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
                                    if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length - 2, 1)
                                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                                        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                                        this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                                        this.setState({ ansArr: this.state.ansArr })
                                    }
                                }
                            } else {
                                let answerSet = { "answer": currentAnswerObject[i].answer, "answerId": currentAnswerObject[i].answerId, "answerIndex": indexVal, 
                                "specify": currentAnswerObject[i].specify, 
                                // "specifyDescription": currentAnswerObject[i].specifyDescription 
                            }
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray = []
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer = []
                                this.state.ansArr = []
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(currentAnswerObject[i].answerId)
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push(answerSet)
                                this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                                this.state.ansArr.push(answerSet)
                                this.setState({ ansArr: this.state.ansArr })
                            }
                        }
                        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
                            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length - 2, 1)
                                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                                this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                                this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                                this.setState({ ansArr: this.state.ansArr })
                            }
                        }
                    }
                    this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                    if (document.querySelector('.highlight-answer')) {
                        document.querySelector('.highlight-answer').className = 'quest-answer-block';
                    }
                }
            }
            if (event.key == "ArrowDown") {
                if (this.state.answerIndex < Object.values(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answers).length) {
                    if (this.state.answerIndex == 0)
                        this.setState({ answerIndex: 1 }, this.navigateAnswer)
                    else
                        this.setState({ answerIndex: this.state.answerIndex + 1 }, this.navigateAnswer)
                }
            }
            if (event.key == "ArrowUp") {
                if (this.state.answerIndex > 1)
                    this.setState({ answerIndex: this.state.answerIndex - 1 }, this.navigateAnswer)
            }
            if (event.key == "ArrowLeft") {
                this.backArrowFunction()
            }
            if (event.key == "ArrowRight") {
                if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Radio") {
                    if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == 1)
                        this.nextClicked()
                } else if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Select") {
                    if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
                        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice)
                            this.nextClicked()
                    }
                    else if (!(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length < Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) || (!Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice))
                        this.nextClicked()
                }
                window.scrollTo(0, 0);
                if (document.querySelector('.highlight-answer'))
                    document.querySelector('.highlight-answer').className = "quest-answer-block"
            }
            if (event.key == "Enter" || event.key == "Tab" || event.code == "Space") {
                if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Radio") {
                    if ((Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length <= 0) && (document.querySelector('.highlight-answer')) && (event.key == "Enter")) {
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray = []
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer = []
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                        document.querySelector('.highlight-answer').className = 'quest-answer-block';
                    } else if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length && (event.key == "Tab")) {
                        this.nextClicked()
                    } else if (document.querySelector('.highlight-answer') && (event.code == "Space" || event.key == "Enter")) {
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray = []
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer = []
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                        document.querySelector('.highlight-answer').className = 'quest-answer-block';
                    } else if (document.querySelector('.selected-answer') && (event.key == "Enter")) {
                        this.nextClicked()
                    }
                }
                else if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Select") {
                    if ((Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length <= 0) && (document.querySelector('.highlight-answer')) && (event.key == "Enter")) {
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                        this.state.ansArr.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                        this.setState({ ansArr: this.state.ansArr })
                        //remove more options
                        this.removeOptions()
                        document.querySelector('.highlight-answer').className = 'quest-answer-block';
                    } else if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length && (event.key == "Tab")) {
                        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
                            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice)
                                this.nextClicked()
                        }
                        else if (!(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length < Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) || (!Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice))
                            this.nextClicked()
                    } else if (document.querySelector('.highlight-answer') && (event.code == "Space" || event.key == "Enter")) {
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(document.querySelector(".highlight-answer input").id)
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                        this.state.ansArr.push({ "answer": document.querySelector(".highlight-answer label span").textContent, "answerId": document.querySelector(".highlight-answer input").id })
                        this.setState({ ansArr: this.state.ansArr })
                        document.querySelector('.highlight-answer').className = 'quest-answer-block';
                        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                            this.checkValidationMsg()
                        }
                        //remove more options
                        this.removeOptions()
                    } else if (document.querySelector('.selected-answer') && (event.key == "Enter")) {
                        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
                            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice)
                                this.nextClicked()
                        }
                        else if (!(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length < Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) || (!Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice))
                            this.nextClicked()
                    }
                }
            }
        }
    }
    backArrowFunction() {
        if (this.state.indexValue >= 1) {
            this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY");
            this.setState({ indexValue: this.state.indexValue - 1, clickedButton: "back", minValidMsg: "" }, this.checkIfSelected)
        }
        if (document.querySelector('.highlight-answer'))
            document.querySelector('.highlight-answer').className = "quest-answer-block"
        this.callImage()
    }
    checkIfSelected() {
        if (this.state.indexValue <= Object.values(this.props.orgQuestion.data).length - 1) {
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Radio") {
                if (document.querySelector('.selected-answer')) {
                    Object.values(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answers).forEach((data, index) => {
                        if (data.answerId == document.querySelector('.selected-answer input').id)
                            this.setState({ answerIndex: index + 1 })
                    })
                } else {
                    this.setState({ answerIndex: 0 })
                }
            } else if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Select") {
                if (document.querySelector('.selected-answer')) {
                    Object.values(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answers).forEach((data, index) => {
                        if (data.answerId == document.querySelector('.selected-answer input').id)
                            this.setState({ answerIndex: index + 1 })
                    })
                } else {
                    this.setState({ answerIndex: 0 })
                }
            }
            //for dashboard progress value
        }
    }
    callImage() {
        var imageArray = ["https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image.png", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image1.png", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image2.png", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image3.png", "https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/start-section-image4.png"]
        var num = Math.floor(Math.random() * imageArray.length);
        this.setState({ selectedImage: imageArray[num] })
    }
    //nextClicked
    nextClicked() {
        this.callImage()
        if (this.state.indexValue <= Object.values(this.props.orgQuestion.data).length) {
            this.setState({ clickedButton: "next" })
            var stateArr = [];
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.length > 0) {
                stateArr = Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer
            } else {
                stateArr = this.state.ansArr
            }
            if (document.querySelector('.highlight-answer')) {
                document.querySelector('.highlight-answer').className = "quest-answer-block"
            }
            this.setState({ indexValue: this.state.indexValue + 1, progressValue: this.state.indexValue + 1, }, this.checkIfSelected)

            let resultSurvey = []
            if (this.props.leadSurvey.length > 0) {
                resultSurvey = this.props.leadSurvey.length
            } else {
                resultSurvey = []
            }
            if (this.state.indexValue == 0 && resultSurvey.length == 0) {
                axios({
                    method: 'POST',
                    url: apiURL + 'survey/addSurvey?access_token=' + localStorage.token,
                    data: {
                        "lead_id": this.props.auth.id,
                        "sponsor_id": this.props.auth.sponsorId,
                        "coupon_id": this.props.auth.couponId,
                        "currentQuestionCategoryId": "5bff7a4efde92255455d7e89",
                        "currentQuestionId": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionId,
                        "survey_details": [{
                            "questionCategoryId": "5bff7a4efde92255455d7e89",
                            "questionCategoryName": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionCategoryName,
                            "questions": [{
                                "question": Object.values(this.props.orgQuestion.data)[this.state.indexValue].question,
                                "questionId": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionId,
                                "questionType": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType,
                                "questionIndex": this.state.indexValue + 1,
                                "order": Object.values(this.props.orgQuestion.data)[this.state.indexValue].order,
                                "answer": stateArr
                            }],
                            "progressValue": [this.state.indexValue, Object.values(this.props.orgQuestion.data).length]
                        }]
                    }
                }).then((res) => {
                    this.setState({ ansArr: [] })
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                    this.props.getSurveyData()
                })
            } else {
                let apiData = {
                    "surveyId": this.props.leadSurvey[this.props.leadSurvey.length - 1].id,
                    "questionCategoryId": "5bff7a4efde92255455d7e89",
                    "questionCategoryName": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionCategoryName,
                    "question": Object.values(this.props.orgQuestion.data)[this.state.indexValue].question,
                    "questionId": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionId,
                    "questionType": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType,
                    "questionIndex": this.state.indexValue + 1,
                    "answer": stateArr,
                    "order": Object.values(this.props.orgQuestion.data)[this.state.indexValue].order,
                    "currentQuestionCategoryId": "5bff7a4efde92255455d7e89",
                    "currentQuestionId": Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionId,
                    "reportDependancy": Object.values(this.props.orgQuestion.data)[this.state.indexValue].reportDependancy,
                    "reportKeyword": Object.values(this.props.orgQuestion.data)[this.state.indexValue].reportKeyword,
                    "custom": Object.values(this.props.orgQuestion.data)[this.state.indexValue].custom,
                    "progressValue": [this.state.indexValue, Object.values(this.props.orgQuestion.data).length]
                }
                if (this.state.indexValue == Object.values(this.props.orgQuestion.data).length - 1)
                    apiData.section = "securityStatus"

                axios({
                    method: 'POST',
                    url: apiURL +'survey/editSurvey?access_token=' + localStorage.token,
                    data: apiData
                }).then((res) => {
                    this.setState({ ansArr: [] })
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                    if (this.state.indexValue == Object.values(this.props.orgQuestion.data).length) {
                        this.props.getSurveyData()
                        let keyValue = {}
                        if (this.props.priorityData.data.length > 0) {
                            this.props.priorityData.data.forEach(rec => {
                                keyValue[rec.name] = rec
                            })
                        }
                        keyValue["Security Strategy"].isComplete = true
                        this.props.setEnabledStatus(Object.values(keyValue))
                    }
                })
            }
        }
        //    else{
        //             window.location.href="/#/organization-recommendation"
        //     }
    }
    navigateAnswer() {
        if (document.querySelector(".highlight-answer")) {
            var currentElementClass = document.querySelector('.highlight-answer').className;
            if (currentElementClass != 'quest-answer-block animated flash selected-answer highlight-answer')
                document.querySelector(".quest-answer-block.highlight-answer").className = 'quest-answer-block';
        }
        var navigateElementClass = document.querySelector(".quest-answer-block:nth-child(" + this.state.answerIndex + ")").className;
        if (navigateElementClass != 'quest-answer-block animated flash selected-answer')
            document.querySelector(".quest-answer-block:nth-child(" + this.state.answerIndex + ")").className += ' highlight-answer';
    }
    //click on checkbox options
    setCheckboxOptions(e, data, index) {
        if (e.target.checked) {
            this.setState({ answerIndex: index + 1 })
            Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.forEach((dbRec, dbInd) => {
                if (dbRec.specify) {
                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(dbInd, 1)
                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(dbInd, 1)
                    this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                    this.state.ansArr.splice(dbInd, 1)
                    this.setState({ ansArr: this.state.ansArr })
                }
            })

            if (!data.specify) {
                let answerSet = { "answer": data.answer, "answerId": data.answerId, "answerIndex": index + 1 }
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(e.target.value)
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push(answerSet)
                this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.push(answerSet)
                //remove more optons
                if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice == Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice) {
                    if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice) {
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length - 2, 1)
                        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.length - 2, 1)
                        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                        this.state.ansArr.splice(this.state.ansArr.length - 2, 1)
                        this.setState({ ansArr: this.state.ansArr })
                    }
                }
            } else {
                let answerSet = {
                    "answer": data.answer, "answerId": data.answerId, "answerIndex": index + 1,
                    "specify": data.specify,
                    // "specifyDescription": data.specifyDescription
                }
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray = []
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer = []
                this.state.ansArr = []
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(e.target.value)
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push(answerSet)
                this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                this.state.ansArr.push(answerSet)
                this.setState({ ansArr: this.state.ansArr })
            }

        }
        else {
            if (data.specify != true) {
                let index = Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.indexOf(e.target.value)
                if (index > -1) {
                    this.setState({ answerIndex: index - 1 })
                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(index, 1)
                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(index, 1)
                    this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                }
                let ind = this.state.ansArr.includes(e.target.value)
                if (index > -1) {
                    this.state.ansArr.splice(ind, 1)
                }
            } else {
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer[0] = { "answerId": data.answerId, "answer": data.answer, 
                // "specify": true, "specifyDescription": "" 
            }
                let index = Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.indexOf(e.target.value)
                if (index > -1) {
                    this.setState({ answerIndex: index - 1 })
                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(index, 1)
                    Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(index, 1)
                    this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
                }
                let ind = this.state.ansArr.includes(e.target.value)
                if (index > -1) {
                    this.state.ansArr.splice(ind, 1)
                }
            }
        }
    }
    //click on radio options
    setRadioOptions(e, data, index) {
        this.setState({ enterClicked: true, answerIndex: index + 1, ansArr: [] })
        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray = []
        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer = []
        Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.push(e.target.value)
        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.push(data)
        this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
        this.state.ansArr.push(data)
    }
    //for radio and select type questions
    getQuestions(arrayData) {
        var list = []
        if (Object.values(arrayData)[this.state.indexValue]) {
            if (Object.values(Object.values(arrayData)[this.state.indexValue].answers).length > 0) {
                Object.values(Object.values(arrayData)[this.state.indexValue].answers).forEach((data, index) => {
                    if (Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId))
                        var answerClass = "quest-answer-block animated flash selected-answer";
                    else
                        var answerClass = "quest-answer-block";

                    if (Object.values(arrayData)[this.state.indexValue].questionType == "Select") {
                        list.push(
                            <div key={index} className={answerClass}>
                                <input name="radio-button" className="radio-answer" checked={Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId)} type="checkbox" onChange={(e) => this.setCheckboxOptions(e, data, index)} value={data.answerId} id={data.answerId} />
                                {data.tooltipDescription ? (
                                    <label htmlFor={data.answerId} className="answer-label"><span className="answer-block"> {data.answer}</span><Tooltip title={data.tooltipDescription}><InfoCircleOutlined twoToneColor="#A9A9A9" theme="twoTone" /></Tooltip></label>
                                ) : (
                                        <label htmlFor={data.answerId} className="answer-label"><span className="answer-block">{data.answer}</span></label>
                                    )}
                            </div>)
                    }
                    if (Object.values(arrayData)[this.state.indexValue].questionType == "Radio") {
                        list.push(
                            <div key={index} className={answerClass}>
                                <input name="radio-button" className="radio-answer" checked={Object.values(arrayData)[this.state.indexValue].answerArray.includes(data.answerId)} type="radio" onChange={(e) => this.setRadioOptions(e, data, index)} value={data.answerId} id={data.answerId} />
                                {data.tooltipDescription ? (
                                    <label htmlFor={data.answerId} className="answer-label"><span className="answer-block">{data.answer}</span><Tooltip title={data.tooltipDescription}><InfoCircleOutlined type="info-circle" twoToneColor="#A9A9A9" theme="twoTone" /></Tooltip></label>
                                ) : (
                                        <label htmlFor={data.answerId} className="answer-label"><span className="answer-block">{data.answer}</span></label>
                                    )}
                            </div>)
                    }

                })
                return list;
            }
        }
    }
    getSpecifyMessage(arrayData) {
        let message = ""
        Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.forEach((rec, ind) => {
            console.log("373667863876",rec)
            if (rec.specify) {
                message = <Input type="text" className="form-control" value={rec.specifyDescription} onChange={(e) => this.changeMessage(e.target.value, rec)} />
            }
        })
        return message
    }
    checkDisabled() {
        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Radio") {
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length == 1) {
                return false
            } else {
                return true
            }
        }
        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionType == "Select") {
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length < Object.values(this.props.orgQuestion.data)[this.state.indexValue].minChoice || Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.length > Object.values(this.props.orgQuestion.data)[this.state.indexValue].maxChoice)
                return true
            else {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
                return false
            }

        }
    }
    handleSponsor(value, i) {
        if (value != undefined) {
            Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray[i] = value.key
            Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer[i] = value
            this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
            this.state.ansArr[i] = value
            this.setState({ ansArr: this.state.ansArr })
        } else {
            if (Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.includes(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray[i])) {
                let index = Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.indexOf(Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray[i])
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].answerArray.splice(index, 1)
                let dbIndex = Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.indexOf(Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer[i])
                Object.values(this.props.orgQuestion.data)[this.state.indexValue].dbAnswer.splice(dbIndex, 1)
                let arrIndex = this.state.ansArr.includes(this.state.ansArr[i])
                this.state.ansArr.splice(arrIndex, 1)
            }
            // this.checkValidationMsg()
            this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
        }
    }
    getFocus() {
        this.setState({ sponsorArray: [] })
    }
    changeSelection(e, record, data, type) {
        if (e.target.checked) {
            Object.values(data)[this.state.indexValue].selectTableObject[record.answerId][type] = true
            Object.values(data)[this.state.indexValue].tableSelection[record.answerId][type] = true
            this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
        } else {
            Object.values(data)[this.state.indexValue].selectTableObject[record.answerId][type] = false
            Object.values(data)[this.state.indexValue].tableSelection[record.answerId][type] = false
            this.props.saveSurvey(this.props.orgQuestion.data, "GET_QUESTION_BY_CATEGORY")
        }
    }
    render() {
        if (Object.values(this.props.orgQuestion.data)[this.state.indexValue] != undefined) {
            var categoryId = Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionCategoryId
            var time = Object.values(this.props.orgQuestion.data).length
            //for no change of time value
            var id;
            if (this.props.leadSurvey.length > 0) {
                const result = this.props.leadSurvey[this.props.leadSurvey.length - 1].survey_details.filter(survey => survey.questionCategoryId == "5bff7a4efde92255455d7e89");
                if (result.length > 0) {
                    id = result[0].questions.length - 1
                } else {
                    id = 0;
                }
            } else {
                id = 0
            }
        }
        return (
            <div>
                <SectionHeader calledButton={this.state.clickedButton} lastIndex={id} currentValue={this.state.indexValue + 1} timeValue={time} id="Security Strategy" total={Object.values(this.props.orgQuestion.data).length} />
                {this.props.app.loading ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                        <img width={"150px"} src="https://xsecurestore.s3-ap-southeast-1.amazonaws.com/images/spinning.gif" />
                    </div>
                ) : (
                        <div className="container animated fadeIn">
                            {Object.values(this.props.orgQuestion.data).length > 0 && (
                                <div className="container">
                                    {(Object.values(this.props.orgQuestion.data).length > 0 && (this.state.indexValue <= Object.values(this.props.orgQuestion.data).length - 1)) ? (
                                        <div className="animated fadeIn single-question-block">
                                            <div className="question-main-header">
                                                <div>
                                                    Question <b>{this.state.indexValue + 1}/{Object.values(this.props.orgQuestion.data).length}</b>
                                                </div>
                                            </div>
                                            {Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionHeader && (
                                                <Tag color="#5f4ce4"> {Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionHeader}</Tag>
                                            )}
                                            <div className="question-header">{Object.values(this.props.orgQuestion.data)[this.state.indexValue].question}</div>
                                            <hr />
                                            <div className="quest-description mb-3" >{Object.values(this.props.orgQuestion.data)[this.state.indexValue].questionDescription}</div>
                                            <div className="answer-option-block row">
                                                <div className="col-sm-8">
                                                    {this.getQuestions(this.props.orgQuestion.data)}
                                                    {/* {this.getSpecifyMessage(this.props.orgQuestion.data)} */}
                                                </div>
                                                <div className="col-sm-4 section-image">
                                                    <img className="img-fluid" src={this.state.selectedImage} />
                                                </div>
                                            </div>
                                            <div className="button-block survey-prev">
                                                <div>
                                                    <Button disabled={this.state.indexValue == 0} onClick={() => this.backArrowFunction()} >BACK</Button>
                                                </div>
                                                <div>
                                                    <span className="validation-error">{this.state.minValidMsg}</span>
                                                    <Button className="button-right-next" disabled={this.checkDisabled()} onClick={() => this.nextClicked()}>NEXT</Button>
                                                </div>

                                            </div>
                                        </div>
                                    ) : (
                                            <Redirect to="/security-recommendation" />
                                        )}
                                </div>
                            )}
                        </div>

                    )}
                {this.state.isRedirect ? <Redirect to={this.state.redirectPath} /> : null}
            </div>
        )
    }
}
function mapStateToProps(state) {
    // console.log("6678",state)
    return {
        priorityData: state.priorityStatus,
        app: state.questionLoader,
        surveyAuth: state.auth,
        orgQuestion: state.getQuestionById,
        auth: state.auth.authData,
        leadSurvey: state.leadSurvey.data,
        auth: state.auth.authData
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        questStartRequest: questStartRequest,
        getSurveyData: getSurveyData,
        getQuestionById: getQuestionById,
        saveSurvey: saveSurvey,
        setEnabledStatus: setEnabledStatus,
        getAuthentication: getAuthentication
    }, dispatch)
}
export default connect(mapStateToProps, matchDispatchToProps)(Security)