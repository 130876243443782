import axios from 'axios';
import { apiURL } from '../env'
import { startRequest, endRequest, endRequestError } from './app';
import { setEnabledStatus } from './setPriority'
export function getSurveyData() {
    return dispatch => {
        dispatch(startRequest())
        axios.get(apiURL + 'leads/getLeadSurveys?access_token=' + localStorage.token)
            .then(function (response) {
                dispatch({
                    type: "GET_LEAD_SURVEY",
                    payload: response.data.data.surveyData
                })
                let obj = {}
                response.data.data.category.forEach((rec) => {
                    obj[rec.id] = { ...rec, isComplete: false }
                })
                let surveyData = response.data.data.surveyData
                if (surveyData.length > 0) {
                    if (surveyData[surveyData.length - 1].securityStatus) {
                        obj["5bff7a4efde92255455d7e89"].isComplete = true
                    }
                    if (surveyData[surveyData.length - 1].threatStatus) {
                        obj["5bff7a4efde92255455d7e8c"].isComplete = true
                    }
                    if (surveyData[surveyData.length - 1].vulnerabilityStatus) {
                        obj["5bff7a4efde92255455d7e8d"].isComplete = true
                    }
                    if (surveyData[surveyData.length - 1].identityStatus) {
                        obj["5bff7a4efde92255455d7e8b"].isComplete = true
                    }
                    if (surveyData[surveyData.length - 1].trustStatus) {
                        obj["5bff7a4efde92255455d7e8a"].isComplete = true
                    }
                }
                dispatch(setEnabledStatus(Object.values(obj)))
            }).then(function (response) {
                dispatch(endRequest())
            }).catch(function response(error) {
                console.log(error)
            })

    }
}