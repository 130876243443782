const initialState = {
    status:false
}
export default function (state = initialState, action) {
    switch (action.type) {
        case "AUTH_CALLED":
            return {
                ...state,
                status: action.status
            }
            break;
    }
    return state
}