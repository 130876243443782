import { createStore, applyMiddleware, compose} from 'redux';
import allReducers from '../reducers';

import thunk from 'redux-thunk';
import promise from 'redux-promise';
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(allReducers, /* preloadedState, */ composeEnhancers(
//    applyMiddleware(thunk, promise)
// ));
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(allReducers, 
   applyMiddleware(thunk, promise)
);
export default  store