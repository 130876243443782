import React,{Component} from 'react'
import { Button, Input } from 'antd';
import SimpleReactVaidator from 'simple-react-validator'
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux'
import { loginCheck } from '../../actions/loginCheck'
import axios from 'axios'
import {apiURL} from '../../env'
import {Redirect} from 'react-router-dom'
import { getAuthentication } from '../../actions/loginCheck'
import {getPage} from '../../actions/getPage'
class Login extends Component{
    constructor(props){
        super(props)
        this.state = {
            email: "",
            password: "",
            focusChanged:false,
            isRedirectStatus:false,
            redirectPath:"",
            invalidMsg:""
        }
        this.validator = new SimpleReactVaidator()
    }
    componentWillMount(){
            this.props.auth.authStatus ? (
                this.props.getPage("home")
            ) : (
                this.props.getPage("login")
            )
        }
    loginClick(e){
        e.preventDefault()
        if (this.validator.allValid()) {
            axios({
                method: 'POST',
                url: apiURL + 'leads/login',
                data: { email: this.state.email.trim().toLowerCase(), password: this.state.password }
            }).then((response) => {
                    localStorage.token = response.data.token.id
                    axios.defaults.headers.common['Authorization'] = response.data.token.id
                    this.props.getAuthentication()
                    if (response.data.sponsorId != undefined) {
                        let expirationDate = new Date(response.data.sponsorLockSixMonthExpiry)
                        let localDate = new Date()
                        if (localDate > expirationDate) {
                            this.setState({ isRedirectStatus: true, redirectPath:"/sponsor"})
                        } else {
                            this.setState({ isRedirectStatus: true, redirectPath: "/dashboard" })
                        }
                    } else {
                        this.setState({ isRedirectStatus: true, redirectPath: "/sponsor" })
                    }
            }).catch(err=>{
                if (err.response==undefined){
                    this.setState({ invalidMsg: err.message, focusChanged: false })
                }else{
                    if (err.response.data.error.statusCode == 401) {
                        this.setState({ invalidMsg: "Invalid email or password", focusChanged: false })
                    }
                }
               
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate()
        }
    }
    render(){
        return (
           <div>
                <div className="form-group">
                    <Input className="form-control" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} onFocus={() => this.setState({ focusChanged: true, invalidMsg:"" })} onKeyPress={(e) => { if (e.key == "Enter") { this.loginClick(e) } }}/>
                    <span style={{color:"red"}}>{this.validator.message('Email', this.state.email, 'required|email', false, { "required": "Email is required" })}</span>
                </div>
                <div className="form-group">
                    <Input className="form-control" type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onFocus={() => this.setState({ focusChanged: true,invalidMsg:"" })} onKeyPress={(e) => { if (e.key == "Enter") { this.loginClick(e) } }}/>
                    <span style={{ color: "red" }}>{this.validator.message('Password', this.state.password, 'required',false,{"required":"Password is required"})}</span>
                </div>
                <div className="form-group">
                    <Button className="btn form-btn" onClick={(e) => this.loginClick(e)}>Login</Button>
                </div>
                {((this.state.invalidMsg!="")&& (!this.state.focusChanged)) && (
                    <span><label style={{ color: "red",fontSize:"12px" }}>INVALID EMAIL OR PASSWORD </label></span>
                )}
                <div className="login-footer">
                    <span className="link-to-signup"><a href="javascript:void(0)" onClick={() => this.props.changeFormStatus()}>Click Here</a> to Register</span><br /><br />
                    <span className="link-to-signup"><a href="javascript:void(0)" style={{ cursor: 'pointer' }} onClick={() => this.props.forgotPassword()}>Forgot Password?</a></span>
                </div>
                {this.state.isRedirectStatus?<Redirect to={this.state.redirectPath}/>:null}
                
           </div>
        )
    }
}
function mapStateToProps(state){
    return {
        auth: state.auth
    }
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({ getPage:getPage,getAuthentication:getAuthentication,loginCheck: loginCheck},dispatch)
}
export default connect(mapStateToProps,matchDispatchToProps)(Login)