import axios from 'axios'
import { apiURL } from '../env'
import { startRequest, endRequest, endRequestError } from './app'
export function getUrlData(url) {
    return dispatch => {
        dispatch(startRequest())
        axios.get(apiURL + 'sponsor/getSponsorContent?sponsor='+url)
            .then((response) => {
              if(response.data.statusCode!=404){
                  dispatch({
                      type: 'GET_URL_DATA',
                      payload: response.data
                  })
              }else{
                  window.location.href="/#/"
              }
                
            })
            .then((res) => {
                dispatch(endRequest())
            })
            .catch((error) => {
                console.log(error)
            })
    }
}